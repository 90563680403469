import React from 'react'
import { Route, RouteComponentProps } from 'react-router-dom'
import { IonRouterOutlet } from '@ionic/react'
import AuthMiddleware from 'middlewares/AuthMiddleware'
import RoleMiddleware from 'middlewares/RoleMiddleware'
import { CONTROLLER } from 'utils/const'
import ControlleurSelection from 'pages/controller/ControlleurSelection'

const DynamicRoleRouter: React.FC<RouteComponentProps> = ({ match }) => {
  return (
    <IonRouterOutlet>
      <AuthMiddleware>
        <RoleMiddleware roles={[CONTROLLER]}>
          <Route
            path={match.url}
            component={ControlleurSelection}
          />
        </RoleMiddleware>
      </AuthMiddleware>
    </IonRouterOutlet>
  )
}

export default DynamicRoleRouter
