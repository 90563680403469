import React, { useEffect } from 'react'
import { Route, RouteComponentProps } from 'react-router-dom'
import { IonRouterOutlet } from '@ionic/react'
import ControlIndex from '.'
import AuthMiddleware from 'middlewares/AuthMiddleware'
import RoleMiddleware from 'middlewares/RoleMiddleware'
import { CONTROLLER } from 'utils/const'
import { setControllerRole } from 'stores/actions/user'
import store from 'stores/store'

const ControlPages: React.FC<RouteComponentProps> = ({ match }) => {
  // Si on est ici c'est forcément que l'on veut aller sur une route controlleur
  useEffect(() => {
    store.dispatch(setControllerRole(CONTROLLER))
  }, [])

  return (
    <IonRouterOutlet>
      <AuthMiddleware>
        <RoleMiddleware roles={[CONTROLLER]}>
          <Route exact path={match.url} component={ControlIndex} />
        </RoleMiddleware>
      </AuthMiddleware>
    </IonRouterOutlet>
  )
}

export default ControlPages
