import { combineReducers } from "@reduxjs/toolkit";
import ThemeReducer from "./theme";
import ToastReducer from "./toast";
import { UserReducer } from "./user";

const rootReducer = combineReducers({
  user: UserReducer,
  toast: ToastReducer,
  theme: ThemeReducer
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
