import ScanButton from 'components/scan/ScanButton/ScanButton'
import Container from 'layouts/containers/Container'
import React from 'react'
import Lottie from 'react-lottie';
import LayoutDefault from '../../layouts/LayoutDefault'
import animationData from '../../assets/animation/scan-qr-code-success.json'
import './Intervenant.scss'

const lottieOptions = {
  loop: false,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};


const InterventionIndex: React.FC<any> = (props) => {
  const isEnd = !!props.location.state?.finish
  return (
    <LayoutDefault title="Interventions">
      <Container paddingTop paddingBottom center>
        <div className="lottie-wrapper">
          <Lottie 
            options={lottieOptions}
            width={300}
          />
        </div>
        <div className="scan__wrap-text">
          <div className="scan__title">
            Scannez le qr code
          </div>
          <div className="scan__text">
            Pour {isEnd ? 'terminer' : 'commencer'} l’opération, scannez le QR code avec la caméra de votre téléphone.
          </div>
        </div>
        <ScanButton />
      </Container>
    </LayoutDefault>
  )
}

export default InterventionIndex
