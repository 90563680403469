import {
  IonButton,
  IonCard,
  IonCardContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList
} from '@ionic/react'
import Container from 'layouts/containers/Container'
import LayoutDefault from 'layouts/LayoutDefault'
import React, { useCallback, useEffect, useState } from 'react'
import {
  checkmarkSharp,
  compassOutline,
  pin,
  qrCodeOutline
} from 'ionicons/icons'
import { Link, RouteComponentProps } from 'react-router-dom'
import animationData from '../../assets/animation/work.json'
import './styles.scss'
import Lottie from 'react-lottie'
import { Callout } from 'interfaces/common'
import { getCallout } from 'api/callout'

const lottieOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
}

interface MatchParams {
  calloutID: string
}

const CalloutInProgress: React.FC<RouteComponentProps<MatchParams>> = ({
  match
}) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [errormsg, seterrormsg] = useState('')
  const [data, setData] = useState<Callout | null>(null)

  const getSpotInfo = useCallback(() => {
    setLoading(true)
    setError(false)

    // Attention: j'ai supprimé la condition isAdmin pour cette route api en attendant d'avoir la bonne route
    // A remettre si besoin
    // src\Controller\Rest\SpotController.php >> getSpotByID
    getCallout(match.params.calloutID)
      .then((res: any) => {
        setData(res)
        console.log('res:', res)
      })
      .catch((error: any) => {
        setError(true)
        if (error === 'errors.error_network') {
          seterrormsg(
            "Un problème est survenue, assurez vous d'être connecté à internet"
          )
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }, [match.params.calloutID])

  useEffect(() => {
    getSpotInfo()
  }, [getSpotInfo])

  if (error) {
    return (
      <LayoutDefault>
        <Container center>
          <p className="ion-text-center">
            {errormsg !== '' ? errormsg : 'Une erreur est survenue'}
          </p>
          <IonButton onClick={getSpotInfo}>Réessayer</IonButton>
        </Container>
      </LayoutDefault>
    )
  }

  return (
    <LayoutDefault>
      <Container paddingTop paddingBottom loading={loading}>
        <IonCard>
          <IonCardContent>
            {data?.spot && (
              <IonList className="spot-list">
                {data.spot.entrance && (
                  <IonItem>
                    <IonIcon icon={compassOutline} color="medium" />
                    <IonLabel>
                      <b>Lieu :</b>
                    </IonLabel>
                    <IonLabel className="ion-text-end" slot="end">
                      {data.spot.entrance.address}
                    </IonLabel>
                  </IonItem>
                )}

                <IonItem>
                  <IonIcon icon={pin} color="medium" />
                  <IonLabel>
                    <b>Spot :</b>
                  </IonLabel>
                  <IonLabel className="ion-text-end" slot="end">
                    {data.spot.name}
                  </IonLabel>
                </IonItem>

                <IonItem lines="none">
                  <IonIcon icon={qrCodeOutline} color="medium" />
                  <IonLabel>
                    <b>Contrôleur :</b>
                  </IonLabel>
                  <IonLabel className="ion-text-end" slot="end">
                    {data.spot.controller.qrCode}
                  </IonLabel>
                </IonItem>

                {data.profession && (
                  <IonItem lines="none">
                    <IonIcon icon={qrCodeOutline} color="medium" />
                    <IonLabel>
                      <b>Métier :</b>
                    </IonLabel>
                    <IonLabel className="ion-text-end" slot="end">
                      {data.profession.name}
                    </IonLabel>
                  </IonItem>
                )}
              </IonList>
            )}
          </IonCardContent>
        </IonCard>
        <Lottie options={lottieOptions} width={200} />
        <div className="intervention">
          Intervention <br />
          en cours
        </div>
        <Link
          to={{
            // pathname: `/${role}`,
            pathname: `/callout/scan`,
            state: { finish: true }
          }}>
          <IonButton className="full">
            <IonIcon slot="end" icon={checkmarkSharp}></IonIcon>TRAVAIL TERMINÉ
          </IonButton>
        </Link>
      </Container>
    </LayoutDefault>
  )
}

export default CalloutInProgress
