import { userState } from "interfaces/common"
import { CONTROLLER, getRole } from "utils/const"


const user: userState = {
  access_token: null,
  refresh_token: null,
  remember_me: false,
  expire_date: 0,
  role: '',
  company: '',
  controllerRole: '',
  email: '',
  firstname: '',
  lastname: '',
  phone: '',
  professions: [],
}

export const UserReducer = (state = user, action: any): userState => {
  switch (action.type) {
    case 'ACCOUNT_SET_DATAS':
      return {
        ...state,
        ...action.data,
        lastUpdated: Date.now(),
        onGoingScoring: action.data.onGoingScoring // pour mettre la valeur à undefined si onGoingScoring n'est pas renvoyé par l'api
      }

    case 'ACCOUNT_DEL_DATAS':
      return user

    case 'persist/REHYDRATE':
      if (action.payload && action.payload.account) {
        return {
          ...action.payload.account,
          lastUpdated: 0
        }
      } else {
        return state
      }

    case 'AUTH_SET_TOKEN':
      return {
        ...state,
        access_token: action.data.access_token,
        refresh_token: action.data.refresh_token,
        expire_date: action.data.expire_date
      }

    case 'AUTH_DEL_TOKEN':
      return {
        ...state,
        access_token: null,
        refresh_token: null,
        expire_date: 0
      }

    case 'AUTH_SET_REMEMBER_ME':
      return {
        ...state,
        remember_me: action.data
      }

    case 'AUTH_DEL_REMEMBER_ME':
      return {
        ...state,
        remember_me: false
      }

    case 'ACCOUNT_SET_CURRENT_PROFESSION': 
      return {
        ...state,
        currentProfession: action.data
      }

    case 'ACCOUNT_DEL_CURRENT_PROFESSION': 
      return {
        ...state,
        currentProfession: undefined
      }
      
    case 'ACCOUNT_SET_ON_GOING_SCORING': 
    return {
      ...state,
      onGoingScoring: action.data
    }

    case 'ACCOUNT_DEL_ON_GOING_SCORING': 
    return {
      ...state,
      onGoingScoring: undefined
    }

    case 'USER_SET_CONTROLLER_ROLE': 
    return {
      ...state,
      controllerRole: getRole(state.role) === CONTROLLER ?  action.data : ''
    }

    // case "USER_INFO_DEL":
    //   return {
    //       access_token: null,
    //       refresh_token: null,
    //       remember_me: false,
    //       expire_date: 0,
    //       role: '',
    //       company: '',
    //       controllerRole: '',
    //       email: '',
    //       firstname: '',
    //       lastname: '',
    //       phone: '',
    //       professions: [],
    //     }


    default:
      return state
  }
}
