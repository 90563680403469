import React from 'react'
import { Route, RouteComponentProps } from 'react-router-dom'
import { IonRouterOutlet } from '@ionic/react'
import AuthMiddleware from 'middlewares/AuthMiddleware'
import RoleMiddleware from 'middlewares/RoleMiddleware'
import { CONTROLLER, INTERVENANT } from 'utils/const'
import ProfessionSelection from '../ProfessionSelection'

const SpotPages: React.FC<RouteComponentProps> = ({ match }) => {
  return (
    <IonRouterOutlet>
      <AuthMiddleware>
        <RoleMiddleware roles={[INTERVENANT, CONTROLLER]}>
          <Route
            path={`${match.url}/:spotID/job-selection`}
            component={ProfessionSelection}
          />
        </RoleMiddleware>
      </AuthMiddleware>
    </IonRouterOutlet>
  )
}

export default SpotPages
