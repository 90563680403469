import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  IonText,
  IonButton
} from '@ionic/react'
import { getEntranceByPosition } from 'api/entrance'
import { Entrance } from 'interfaces/common'
import Container from 'layouts/containers/Container'
import LayoutDefault from 'layouts/LayoutDefault'
import LayoutSearch from 'layouts/LayoutSearch'
import React, { useEffect, useState, useRef } from 'react'
import { getPosition } from 'utils/callout'
import { useHistory } from 'react-router'

export const AdminList: React.FC = () => {
  const [visible, setVisible] = useState(3)
  const [nearEntries, setNearEntries] = useState<Entrance[]>([])
  const [error, seterror] = useState(false)
  const [loading, setloading] = useState(true)
  const [errormsg, seterrormsg] = useState('')
  const history = useHistory()
  const isMounted = useRef(true)

  // bouton qui montre les 2 prochaines entrées les plus proches (s'éfface quand les 20 résultats sont exposés)
  const loadMore = () => {
    setVisible(visible + 2)
  }

  useEffect(() => {
    const searchNearEntrance = async() => {
      setloading(true)
      seterror(false)
      const position = await getPosition()
      if(isMounted.current) {
      var lat = position?.coords.latitude
      var long = position?.coords.longitude
      if (lat != null && long != null) {
        getEntranceByPosition(lat, long)
        .then((results: any) => {
          setNearEntries(results)
        })
        .catch((err: any) => {
          console.log(err)
          if (err === 'errors.error_network') {
            seterrormsg(
              "Un problème est survenue, assurez vous d'être connecté à internet"
              )
            }
            seterror(true)
          })
          .finally(() => {
            setloading(false)
          })
        }
        else {
          seterrormsg(
            "Un problème est survenue, assurez vous d'avoir partagé votre géolocalisation"
          )
        }
      }
      }
      searchNearEntrance()
      return(() => {
        isMounted.current = false})
  }, [])


  const DefaultList = () => {
    return (
      <>
        <div className="category-title">Entrées proches</div>
        {nearEntries.slice(0, visible).map((entrance: any) => (
          <IonCard
            key={entrance.id}
            className="ion-text-center rounder"
            routerLink={`/admin-QR/entrance/${entrance.id}`}>
            <IonCardHeader>
              <IonCardTitle>{entrance.name}</IonCardTitle>
              <IonCardSubtitle>{entrance.commune}</IonCardSubtitle>
            </IonCardHeader>
            <IonCardContent>
              <IonText color="dark">
                Code de l'entrée : {entrance.code}
                <br />
                Située à{' '}
                {entrance.distance <= 10
                  ? 'côté de vous'
                  : Math.round(entrance.distance) + ' mètres de vous'}
              </IonText>
            </IonCardContent>
          </IonCard>
        ))}
        {visible < nearEntries.length && (
          <IonButton
            shape="round"
            size="small"
            className="moreButton"
            fill="solid"
            onClick={loadMore}
            color="dark">
            +
          </IonButton>
        )}
      </>
    )
  }

  if (error) {
    return (
      <LayoutDefault>
        <Container center>
          <p className="ion-text-center">
            {errormsg !== '' ? errormsg : 'Une erreur est survenue'}
          </p>
          <IonButton onClick={() => history.push('/admin-QR/list')}>Réessayer</IonButton>
        </Container>
      </LayoutDefault>
    )
  }

  return (
    <LayoutSearch>
      <Container paddingTop paddingBottom loading={loading}>
        <DefaultList />
      </Container>
    </LayoutSearch>
  )
}
