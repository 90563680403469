import React, { useState, useEffect } from 'react'
import { useHistory } from "react-router";
import {
  IonButton,
  IonCardContent,
  IonCardHeader,
  IonSelect,
  IonContent,
  IonIcon,
  IonImg,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonText,
  IonSelectOption,
  IonToast
} from '@ionic/react'
import { CompanieData, ProfessionData, SignObject } from '../../interfaces/common'
import './Signup.scss'
import { companiesList, professionsList, signup } from '../../api/user'
import Container from 'layouts/containers/Container'
import { eye, eyeOff } from 'ionicons/icons'

const Signup: React.FC = () => {
  const [loadingCompanies, setLoadingCompanies] = useState(true);
  const [loadingProfessions, setLoadingProfessions] = useState(true);
  const [error, seterror] = useState(false);
  const [errormsg, seterrormsg] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [plainPassword, setPlainPassword] = useState('');
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [phone, setPhone] = useState('');
  const [company, setCompany] = useState('');
  const [professions, setProfessions] = useState([])
  const [companiesData, setCompaniesData] = useState<CompanieData[]>([]);
  const [professionsData, setProfessionsData] = useState<ProfessionData[]>([]);
  const [role, setRole] = useState("");
  const router = useHistory();
  const [showToast, setShowToast] = useState(false);

  const redirect = () => {
    setTimeout(() => {
      router.push('/guest/login')
    }, 2000);
  }


  var signObject: SignObject;

  const _signup = (event: any) => {
    event.preventDefault()
    signObject = {
      "plainPassword": plainPassword,
      "email": email,
      "firstname": firstname,
      "lastname": lastname,
      "phone": phone,
      "company": company,
      "professions": professions,
      "roles": [role]
    }
    seterror(false)
    seterrormsg('')
    if (company === "" && professions === [] && role === "") {
      seterrormsg(
        "Un problème est survenue, assurez vous d'avoir rempli l'intégralité des champs"
      )
    } else {
      signup(signObject)
        .then((response: any)=> {
          setShowToast(true)
          redirect()
          
        })
        .catch((error: any) => {
          seterror(true)
          if (error.data === "Invalid parameters") {
            seterrormsg(
              "Un problème est survenue, assurez vous d'avoir correctement rempli l'intégralité des champs"
            )
          } else {
            seterrormsg(
               "Un problème est survenue, assurez vous d'être connecté à internet"
            )
          }
        })
    }
  }
  
  const _compagniesList = () => {
    companiesList()
    .then((response: any) => {
      console.log('res', response)
      setCompaniesData(response)
    })
    .catch((error: any) => {
      seterror(true)
      console.log('error', error)
      seterrormsg(
        "Un problème est survenue, assurez vous d'être connecté à internet"
        )
      })
      .finally(() => setLoadingCompanies(false))
    }
    
    const _professionsList = () => {
      professionsList()
      .then((response: any) => {
        console.log('res', response)
        setProfessionsData(response)
      })
      .catch((error: any) => {
        seterror(true)
        console.log('error', error)
        seterrormsg(
          "Un problème est survenue, assurez vous d'être connecté à internet"
          )
        })
        .finally(() => setLoadingProfessions(false))
      }
      
      useEffect(() => {
        _compagniesList()
        _professionsList()
        
      }, [])
      
  return (
    <IonPage>
      <IonContent fullscreen>
        <Container loading={loadingProfessions && loadingCompanies}>
          <form onSubmit={_signup}>
            <IonCardHeader>
              <IonImg src="assets/images/logo_vilogia.png" class="logo" />
              <h1 className="account-title">Créer votre compte</h1>
            </IonCardHeader>
            {error && (
              <IonText color="danger">
                {errormsg}
              </IonText>
            )}

            <IonItem lines="none">
              <IonInput
                required
                value={email}
                placeholder="Email"
                onIonChange={(e) => setEmail(e.detail.value!)}
                inputMode="email"
                />
            </IonItem>

            <IonItem lines="none">
              <IonInput
                required
                type={showPassword ? 'text' : 'password'}
                value={plainPassword}
                placeholder="Mot de passe"
                onIonChange={(e) => setPlainPassword(e.detail.value!)}
                />
              <IonButton
                className="button-show-password"
                fill="clear"
                slot="end"
                onClick={() => setShowPassword(!showPassword)}>
                <IonIcon icon={showPassword ? eyeOff : eye} />
              </IonButton>
            </IonItem>

            <IonItem lines="none">
              <IonInput
                required
                value={firstname}
                placeholder="Prénom"
                onIonChange={(e) => setFirstname(e.detail.value!)}
                inputMode="text"
                />
            </IonItem>

            <IonItem lines="none">
              <IonInput
                required
                value={lastname}
                placeholder="Nom"
                onIonChange={(e) => setLastname(e.detail.value!)}
                inputMode="text"
                />
            </IonItem>

            <IonItem lines="none">
              <IonInput
                required
                value={phone}
                placeholder="Téléphone"
                onIonChange={(e) => setPhone(e.detail.value!)}
                inputMode="tel"
                />
            </IonItem>

            <IonItem>
            <IonLabel>Compagnie</IonLabel>
              <IonSelect value={company} placeholder="Choisissez une compagnie" onIonChange={e => setCompany(e.detail.value)}>
                {companiesData.map((companieData: CompanieData) => (
                  <IonSelectOption key={companieData.id} value={companieData.id}>{companieData.name}</IonSelectOption>
                ))}
              </IonSelect>
            </IonItem>

            <IonItem>
            <IonLabel>Rôle</IonLabel>
              <IonSelect value={role} placeholder="Choisissez un rôle" onIonChange={e => setRole(e.detail.value)}>
                <IonSelectOption key="1" value="ROLE_INTERVENANT">Intervenant</IonSelectOption>
                <IonSelectOption key="2" value="ROLE_SUPERVISOR">Superviseur</IonSelectOption>
                <IonSelectOption key="3" value="ROLE_CONTROLLER">Contrôleur</IonSelectOption>
                <IonSelectOption key="4" value="ROLE_ADMIN_QR">Admin QR</IonSelectOption>
              </IonSelect>
            </IonItem>

            <IonItem>
            <IonLabel>Profession</IonLabel>
              <IonSelect multiple disabled={role === "" ? true : false} value={professions} placeholder="Choisissez une à plusieurs professions" onIonChange={e => setProfessions(e.detail.value)}>
                {professionsData.filter(prof => prof.role === role).map((professionData: ProfessionData) => (
                  <IonSelectOption key={professionData.id} value={professionData.id}>{professionData.name}</IonSelectOption>
                ))}
              </IonSelect>
            </IonItem>

            <IonCardContent>
              <IonButton
                expand="block"
                type="submit"
                color="primary"
                size="default"
                class="btn_rounded">
                INSCRIPTION
              </IonButton>
            </IonCardContent>
          </form>
          <IonToast
            isOpen={showToast}
            onDidDismiss={() => setShowToast(false)}
            message="Inscription réussie"
            duration={2000}
            color="primary"
          />
        </Container>
      </IonContent>
    </IonPage> 
  )
}

export default Signup
