import { IonButton, IonInput, IonItem, IonLabel, IonText } from '@ionic/react'
import { getDataBySearch } from 'api/entrance'
import { Results, SearchTabs } from 'components/menu/searchbar/SearchTabs'
import { PhotoInterface } from 'hooks/usePhotoGallery'
import { ReportType, Spot } from 'interfaces/common'
import Container from 'layouts/containers/Container'
import LayoutDefault from 'layouts/LayoutDefault'
import BackButton from 'pages/admin/backButton'
import React, { useEffect, useState } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'

const Search: React.FC<
  RouteComponentProps<
    any,
    any,
    {
      reportType: ReportType
      spot: Spot
      message?: string
      photos?: Array<PhotoInterface>
      fromPath?: string
    }
  >
> = ({ location }) => {
  const [results, setresults] = useState<Results>()
  const [search, setsearch] = useState('')
  const [loading, setloading] = useState(false)
  const [error, seterror] = useState(false)
  const [errormsg, seterrormsg] = useState('')
  const [showTabs, setShowTabs] = useState(false)
  const [redirect, setredirect] = useState('/admin-QR')
  const router = useHistory()

  useEffect(() => {
    if (!location.state) {
      router.push('/')
      return
    }

    switch (location.state.fromPath) {
      case '/report/list':
        setredirect('report')
        break
      case '/admin-QR/list':
      default:
        setredirect('admin-QR')
        break
    }
  }, [])

  useEffect(() => {
    searchData()
  }, [search])

  const searchData = () => {
    if (search.length > 2) {
      seterror(false)
      setloading(true)
      getDataBySearch(search)
        .then((data: any) => {
          setShowTabs(true)
          setresults(data)
        })
        .catch((error: any) => {
          console.log(error)
          if (error === 'errors.error_network') {
            seterrormsg(
              "Un problème est survenue, assurez vous d'être connecté à internet"
            )
          }
          seterror(true)
        })
        .finally(() => {
          setloading(false)
        })
    } else {
      setresults(undefined)
      setShowTabs(false)
    }
  }

  if (error) {
    return (
      <LayoutDefault>
        <Container center>
          <p className="ion-text-center">
            {errormsg !== '' ? errormsg : 'Une erreur est survenue'}
          </p>
          <IonButton onClick={searchData}>Réessayer</IonButton>
        </Container>
      </LayoutDefault>
    )
  }

  return (
    <LayoutDefault>
      <Container paddingTop>
        <BackButton url="/" />
        <IonItem>
          <IonLabel position="stacked">Recherche</IonLabel>
          <IonInput
            autofocus
            value={search}
            placeholder="Rechercher un groupe, bâtiment..."
            onIonChange={(e) => setsearch(e.detail.value!)}
            type="text"
            debounce={200}
          />
        </IonItem>
        <div className="ion-padding-top">
          <Container loading={loading} noPadding>
            {showTabs ? (
              <SearchTabs
                results={results}
                url={redirect}
                location={location.state}
              />
            ) : (
              <IonText>Veuillez taper une recherche pour commencer</IonText>
            )}
          </Container>
        </div>
      </Container>
    </LayoutDefault>
  )
}

export default Search
