type themeState = {
  dark?: boolean
}

const theme: themeState = {
  dark: undefined
}

const ThemeReducer = (state = theme, action: any): themeState => {
  switch (action.type) {
    case 'SET_THEME':
      return {
        ...state,
        dark: action.data
      }

    default:
      return state
  }
}

export default ThemeReducer
