import { toast } from "interfaces/common"

export function setToastMessages(data: string | toast | (string|toast)[]) {
  return {
    type: "TOAST_SET_MESSAGES",
    data: data,
  }
}
export function delCurrentMessage() {
  return {
    type: "TOAST_DEL_CURRENT_MESSAGE",
  }
}