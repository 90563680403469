import './Admin.scss'
import React, { useCallback, useEffect, useState } from 'react'
import LayoutDefault from '../../layouts/LayoutDefault'
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonHeader,
  IonItem,
  IonText
} from '@ionic/react'
import { arrowBack } from 'ionicons/icons'
import Container from 'layouts/containers/Container'
import { getBuilding } from 'api/building'
import { Building } from 'interfaces/common'
import { useHistory } from 'react-router'
import BackButton from './backButton'

const AdminBuilding: React.FC = (props: any) => {
  const [loading, setloading] = useState(true)
  const [error, seterror] = useState(false)
  const [errormsg, seterrormsg] = useState('')
  const [building, setbuilding] = useState<Building>({
    id: 0,
    name: '',
    code: ''
    // zipcode: '',
    // address: ''
  })
  const { buildingID } = props.match.params
  const startUrl = props.match.url.split('/')[1]

  const router = useHistory()

  const getBuildings = useCallback(() => {
    setloading(true)
    seterror(false)
    seterrormsg('')
    getBuilding(buildingID)
      .then((data: any) => {
        setbuilding(data)
      })
      .catch((error: any) => {
        console.log(error)
        if (error === 'errors.error_network') {
          seterrormsg(
            "Un problème est survenue, assurez vous d'être connecté à internet"
          )
        }
        seterror(true)
      })
      .finally(() => {
        setloading(false)
      })
  }, [buildingID])

  useEffect(() => {
    getBuildings()
  }, [buildingID, getBuildings])

  const handleClick = (path: string) => {
    router.push(path, props.location.state)
  }

  if (error) {
    return (
      <LayoutDefault>
        <Container center>
          <p className="ion-text-center">
            {errormsg !== '' ? errormsg : 'Une erreur est survenue'}
          </p>
          <IonButton onClick={getBuildings}>Réessayer</IonButton>
        </Container>
      </LayoutDefault>
    )
  }

  return (
    <LayoutDefault>
      <Container paddingTop paddingBottom loading={loading}>
        <BackButton url="/" />

        <div className="category-title">Bâtiment {building.name}</div>

        {/* <IonItem class="item-address thumbnail" lines="none" detailIcon="none">
          <div className="left_part">
            <div className="address-label">Adresse :</div>
            <div className="address">{`${building.address} ${building.zipcode}`}</div>
          </div>
        </IonItem> */}

        <IonItem class="item-address thumbnail" lines="none" detailIcon="none">
          <div className="left_part">
            <div className="address-label">Code : {building.code}</div>
          </div>
        </IonItem>

        <IonText color="dark">
          <div className="entrance-title">Liste des entrées</div>
        </IonText>

        <div className="admin-list">
          {/* Liste des entrées pour le batiment */}

          {building.entrances?.map((entrance) => (
            <div className="entrance" key={entrance.id + entrance.name}>
              <div className="entrance-header">
                <IonText color="dark" className="entrance-name">
                  {entrance.name} - {entrance.code}
                </IonText>
              </div>

              {/* Liste des spots pour chaque entrée */}

              {!entrance.spots ||
                (entrance.spots.length === 0 && (
                  <p>Il n'existe aucun spot pour cette entrée</p>
                ))}

              {entrance.spots?.map((spot) => (
                <IonItem
                  class="spot thumbnail"
                  onClick={() => handleClick(`/${startUrl}/spots/${spot.id}`)}
                  key={spot.id + spot.name}
                  lines="none"
                  detail
                  button>
                  <div className="left_part">
                    <div className="name">{spot.name}</div>
                  </div>
                </IonItem>
              ))}
            </div>
          ))}
        </div>
      </Container>
    </LayoutDefault>
  )
}

export default AdminBuilding
