import { toast, toastState } from "interfaces/common"

const opt = {
  duration: 2000
}
const user: toastState = {
  notifs: []
}

const ToastReducer = (state = user, action: any): toastState => {
  switch (action.type) {
    case 'TOAST_SET_MESSAGES':

      const data: string | toast | (string|toast)[] = action.data

      const arr: (string|toast)[] = (Array.isArray(data)) ? data : (typeof data === "string") ? [{message: data}] : [data];
      
      const toasts: toast[] = arr.map((el: toast | string): toast => {
        let obj: toast = (typeof el === "string") ? {message: el} : el

        return {
          message: obj.message,
          duration: obj.duration || opt.duration
        }
      });


      return {
        ...state,
        notifs: [
          ...state.notifs,
          ...toasts
        ]
      }

    case 'TOAST_DEL_CURRENT_MESSAGE':
      return {
        ...state,
        notifs: state.notifs.filter(( _ , i) => (i !== 0))
      }

    default:
      return state
  }
}

export default ToastReducer