import {
  IonButton,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  IonText
} from '@ionic/react'
import { getEntranceByPosition } from 'api/entrance'
import Map, { ClickableMarker, MapInformationModal } from 'components/map/Map'
import { Entrance } from 'interfaces/common'
import LayoutDefault from 'layouts/LayoutDefault'
import L from 'leaflet'
import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router'
import { getPosition } from 'utils/callout'
import './Admin.scss'
import { Marker } from 'react-leaflet'

const getIcon = (_iconSize: any) => {
  return L.icon({
    iconUrl: require('./Icons/pin.png'),
    iconSize: _iconSize
  })
}

export const AdminMap: React.FC = () => {
  const [error, seterror] = useState(false)
  const [errormsg, seterrormsg] = useState('')
  const history = useHistory()
  const isMounted = useRef(true)
  const [latitude, setLatitude] = useState(Number)
  const [longitude, setLongitude] = useState(Number)
  
  const [nearEntries, setNearEntries] = useState<Entrance[]>([])
  const [showInformationModal, setShowInformationModal] = useState(false)
  useEffect(() => {
    const getLocalisation = async() => {
      const position = await getPosition()
      if(isMounted.current) {
      var lat = position?.coords.latitude
      var long = position?.coords.longitude
      if (lat != null && long != null) {
        setLatitude(lat)
        setLongitude(long)
        getEntranceByPosition(lat, long)
        .then((results: any) => {
          setNearEntries(results)
        })
        .catch((err: any) => {
          console.log(err)
          if (err === 'errors.error_network') {
            seterrormsg(
              "Un problème est survenue, assurez vous d'être connecté à internet"
              )
            }
            seterror(true)
          })
      }
      else {
        seterrormsg(
          "Un problème est survenue, assurez vous d'avoir partagé votre géolocalisation"
        )
      }
      }
    }
      getLocalisation()
      return(() => {
        isMounted.current = false})
  }, [])

  const [currentMarker, setCurrentMarker] = useState<Entrance>()

  const modalCall = (entrance: Entrance) => {
    setShowInformationModal(true)
    setCurrentMarker(entrance)
  }

  return (
    <LayoutDefault>
      {error && (
        <div className="error-center">
          <p className="ion-text-center">
            {errormsg !== '' ? errormsg : 'Une erreur est survenue'}
          </p>
          <IonButton onClick={() => history.push('/admin-QR/map')} color="dark">
            Réessayer
          </IonButton>
        </div>
      )}
      {error ? null : (
        <Map>
          {nearEntries.map((entrance) => (
            <div className="clickableMarker" key={entrance.id}>
              <ClickableMarker
                position={[entrance.latitude, entrance.longitude]}
                callback={modalCall}
                data={entrance}></ClickableMarker>
            </div>
          ))}
          <MapInformationModal
            visible={showInformationModal}
            dismiss={() => setShowInformationModal(false)}
            route={`/admin-QR/entrance/${currentMarker?.id}`}>
            <IonCardHeader>
              <IonCardTitle>{currentMarker?.name}</IonCardTitle>
              <IonCardSubtitle>{currentMarker?.code}</IonCardSubtitle>
            </IonCardHeader>
            <IonCardContent>
              <IonText color="dark">
                Code postal : {currentMarker?.zipcode}
              </IonText>
              <br />
              <IonText color="dark">Commune : {currentMarker?.commune}</IonText>
            </IonCardContent>
          </MapInformationModal>
          <Marker position={[latitude, longitude]} icon={getIcon(40)}></Marker>
        </Map>
      )}
    </LayoutDefault>
  )
}
