import React, { ReactNode } from 'react'
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonIcon,
  IonMenuButton,
  IonRippleEffect
} from '@ionic/react'
import { menu } from 'ionicons/icons'
import './LayoutDefault.scss'
import { search } from 'ionicons/icons'
import { useHistory } from 'react-router'

interface LayoutSearchProps {
  children: ReactNode
  title?: string
}

const LayoutSearch: React.FC<LayoutSearchProps> = ({ children, title }) => {
  const router = useHistory()

  const gotoSearch = () => {
    router.push('/search', {
      // @ts-ignore
      ...router.location.state,
      fromPath: router.location.pathname
    })
  }

  return (
    <IonPage>
      <IonHeader mode="md">
        <div>
          <IonMenuButton
            autoHide={false}
            className="menu_button menu_button__text">
            <IonIcon icon={menu} color="dark"></IonIcon>
            <span className="menu_button__label">MENU</span>
          </IonMenuButton>
          <IonRippleEffect type="unbounded"></IonRippleEffect>
        </div>
        <IonIcon
          icon={search}
          color="dark"
          class="search-icon"
          onClick={gotoSearch}></IonIcon>
      </IonHeader>

      <IonContent fullscreen id="content" className="content-page">
        {title && (
          <IonHeader collapse="condense">
            <IonToolbar>
              <IonTitle size="large">{title}</IonTitle>
            </IonToolbar>
          </IonHeader>
        )}
        <div>{children}</div>
      </IonContent>
    </IonPage>
  )
}

export default LayoutSearch
