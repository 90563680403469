import React, { useState } from 'react'
import {
  IonButton,
  IonCardContent,
  IonCardHeader,
  IonCheckbox,
  IonContent,
  IonIcon,
  IonImg,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonText
} from '@ionic/react'
import './Login.scss'
import { login } from '../../api/helper'
import ForgotPassword from './ForgotPassword'
import Container from 'layouts/containers/Container'
import { eye, eyeOff } from 'ionicons/icons'

const Login: React.FC = () => {
  const [loading, setloading] = useState(false)
  const [error, seterror] = useState(false)
  const [errormsg, seterrormsg] = useState('')
  const [username, setusername] = useState('')
  const [password, setpassword] = useState('')
  const [checked, setchecked] = useState(true)
  const [showPassword, setShowPassword] = useState(false)

  const _login = (event: any) => {
    event.preventDefault()
    setloading(true)
    seterror(false)
    seterrormsg('')
    login(username, password, checked)
      .catch((error) => {
        seterror(true)
        console.log('error', error)
        if (error === 'errors.error_network') {
          seterrormsg(
            "Un problème est survenue, assurez vous d'être connecté à internet"
          )
        } else if (
          error === "Ce compte n'est pas encore ou n'est plus actif."
        ) {
          seterrormsg("Votre n'a pas accès à cette application")
        } else if (typeof error === 'string') {
          seterrormsg(error)
        }
      })
      .finally(() => setloading(false))
  }

  return (
    <IonPage>
      <IonContent fullscreen>
        <Container loading={loading}>
          <form onSubmit={_login}>
            <IonCardHeader>
              <IonImg src="assets/images/logo_vilogia.png" class="logo" />
            </IonCardHeader>
            {error && (
              <IonText color="danger">
                {errormsg !== '' ? errormsg : 'Identifiants invalides'}
              </IonText>
            )}
            <IonItem lines="none">
              <IonInput
                value={username}
                placeholder="Email"
                onIonChange={(e) => setusername(e.detail.value!)}
                type="email"
              />
            </IonItem>
            <IonItem lines="none">
              <IonInput
                type={showPassword ? 'text' : 'password'}
                value={password}
                placeholder="Mot de passe"
                onIonChange={(e) => setpassword(e.detail.value!)}
              />
              <IonButton
                className="button-show-password"
                fill="clear"
                slot="end"
                onClick={() => setShowPassword(!showPassword)}>
                <IonIcon icon={showPassword ? eyeOff : eye} />
              </IonButton>
            </IonItem>

            <IonItem lines="none" class="no-ripple">
              <IonCheckbox
                checked={checked}
                onIonChange={(e) => setchecked(e.detail.checked)}
                color="primary"
                mode="ios"
              />
              <IonLabel>Se souvenir de moi</IonLabel>
            </IonItem>
            <ForgotPassword />
            <IonCardContent>
              <IonButton
                expand="block"
                type="submit"
                color="primary"
                size="default"
                class="btn_rounded">
                CONNEXION
              </IonButton>
              <IonButton
                href={`${process.env.REACT_APP_BACKEND}/loginapp`}
                expand="block"
                type="button"
                color="secondary"
                size="default"
                class="btn_rounded">
                CONNEXION AZURE AD
              </IonButton>
              <IonButton 
                href={`/guest/signup`}
                expand="block"
                type="button"
                color="secondary"
                size="default"
                class="btn_rounded">
                CREER VOTRE COMPTE
                </IonButton>
            </IonCardContent>
          </form>
        </Container>
      </IonContent>
    </IonPage>
  )
}

export default Login
