import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { App, URLOpenListenerEvent } from '@capacitor/app'

const AppUrlListener: React.FC<any> = () => {
  let history = useHistory()
  useEffect(() => {
    App.addListener('appUrlOpen', (data: URLOpenListenerEvent) => {

      // On récupère les paramètres de query
      const queryparams = data.url.split('complete').pop()

      // On redirige vers la page concernée, mais au niveau de l'app, avec les paramètres reçus
      history.push('/guest/complete' + queryparams)
    })
  }, [history])

  return null
}

export default AppUrlListener
