import './Admin.scss'
import React, {  } from 'react'
import {
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonIcon} from '@ionic/react'
import { Route, RouteComponentProps } from 'react-router'
import { map as IconMap, reorderFour } from 'ionicons/icons'
import { AdminMap } from './map'
import { AdminList } from './list'

const AdminIndex: React.FC<RouteComponentProps> = ({ match }) => {
  return (
    <IonTabs>
      <IonRouterOutlet>
        <Route exact path={`/admin-QR/list`} component={AdminList} />
        <Route exact path={`/admin-QR/map`} component={AdminMap} />
      </IonRouterOutlet>
      <IonTabBar slot="bottom" className="tab-bottom">
        <IonTabButton tab="list" href={`/admin-QR/list`}>
          <IonIcon icon={reorderFour} />
          {/* <IonLabel>Liste</IonLabel> */}
        </IonTabButton>

        <IonTabButton tab="map" href={`/admin-QR/map`}>
          <IonIcon icon={IconMap} />
          {/* <IonLabel>Map</IonLabel> */}
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  )
}

export default AdminIndex
