import axios from 'axios'
import {
  delRememberMe,
  delTokens,
  delUserInformations,
  setRememberMe,
  setTokens,
  setUserInformations
} from '../stores/actions/user'
import store from '../stores/store'
import { accountData } from './account'

export const BASE_URI = process.env.REACT_APP_API

// const CLIENT_ID = '1_gn2SG6ku8AFAzqY4Au5UqCAJSva6G6ui773e348'
// const CLIENT_SECRET = '392mSDJqr8pu62wD6mTLPU8e5P3tZj3Tc7Nv55hT'

const CLIENT_ID = process.env.REACT_APP_CLIENT_ID
const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET

const debugActive = true
const insAxios = axios.create()
const expireInDay = 30

let isRefreshing = false
let refreshSubscribers = []

/**
 * Requête API prêt à utiliser
 * @param {String} endPoint Fin d'url
 * @param {Object} payload Paramètres à passer
 * @param {String} method get / post / put / ...
 * @param {Object} headers Header supplémentaire à passer
 */
export function axiosApi(endPoint, payload = {}, method = 'get', headers = {}) {
  let config = {
    method: method,
    url: `${BASE_URI}${endPoint}`,
    headers: headers
  }
  if (method === 'get') {
    config.params = payload
  } else {
    config.data = payload
  }
  return insAxios(config)
}

const debug = (label, obj) => {
  if (debugActive) {
    // console.log(
    //   `%c ${label}: %c ${obj.config.url} %c ${obj.config.method} `,
    //   "color: white; font-style: italic; font-weight: bold; background-color: #d81365; padding: 2px",
    //   "color: black;  font-style: normal; font-weight: normal; background-color: transparent; padding: 0px",
    //   "color: white; font-weight: bold; background-color: #d81365; padding: 2px; text-transform: uppercase"
    // );
    // console.log(obj)
  }
}

const simplifyResponse = (response) => {
  debug('SUCCESS', response)
  if (response.data.data) return response.data.data
  else return response.data
}

const simplifyError = (error) => {
  debug('ERROR', error.response)
  if (error.response.status === 500) return 'errors.error_500'
  if (error.response.data.data && error.response.data.data.user_error)
    return error.response.data.data.user_error
  if (error.response.status === 409)
    return { ...error.response.data, errors_status: 409 }
  // if (error.response.status === 403)
  //   window.location.replace('/');
  else return error.response.data
}

/**
 * On intercepte la requête ici
 */
insAxios.interceptors.request.use(
  (config) => {
    // Récupère l'AccessToken
    const access_token = store.getState().user.access_token

    // Avant requête API on vérifie l'expiration de nos tokens
    const expire_date = store.getState().user.expire_date
    if (expire_date && expire_date < Date.now()) {
      debug('EXPIRATION TOKEN', { config })

      // On retourne une promesse qui ne sera jamais résolu
      return new Promise((resolve) => {
        // Suppression REMEMBER ME email et password + deconnexion
        store.dispatch(delRememberMe())
        logout()
      })
    }

    // Headers par default
    const defaultHeaders = {
      'Content-Type': 'application/json;charset=utf-8',
      Authorization: `Bearer ${access_token}`
    }

    return {
      ...config,
      headers: Object.assign(defaultHeaders, config.headers)
    }
  },
  (error) => {
    return Promise.reject(error)
  }
)

/**
 * On intercepte la réponse ici
 */
insAxios.interceptors.response.use(
  (response) => {
    // Si tout va bien on simplifie la réponse
    return simplifyResponse(response)
  },
  (error) => {
    if (!error.response) {
      console.log(JSON.stringify(error))
      return Promise.reject('errors.error_network')
    }

    const { status, config } = error.response

    // GESTION 401
    if (status === 401) {
      debug('REFRESH', error.response)
      const remember_me = store.getState().user.remember_me

      // Si pas déjà en train de rafraichir le token
      if (!isRefreshing && remember_me) {
        isRefreshing = true

        // On demande de nouveaux token
        const refresh_token = store.getState().user.refresh_token
        console.log(refresh_token)
        axios({
          method: 'post',
          url: `${BASE_URI}oauth/v2/token`,
          data: {
            grant_type: 'refresh_token',
            client_id: CLIENT_ID,
            client_secret: CLIENT_SECRET,
            refresh_token: refresh_token
          }
        }).then(
          (refreshTokenResponse) => {
            // On a récupéré de nouveaux tokens
            const tokens = refreshTokenResponse.data
            isRefreshing = false
            // Mettre à jour les tokens dans redux
            // TODO Remettre le dispatch token
            store.dispatch(setTokens(tokens))

            // Relancer les requêtes
            onRefreshed(tokens.access_token)
          },
          (refreshTokenError) => {
            // On a pas récupéré de nouveaux tokens
            isRefreshing = false
            debug('REFRESH ERROR', refreshTokenError.response)
            logout()
          }
        )
      }

      // Stocke la requête pour la relancer plus tard avec le bon token
      const originalRequest = config
      const retryOrigReq = new Promise((resolve) => {
        subscribeTokenRefresh((acces_token) => {
          originalRequest.headers['Authorization'] = 'Bearer ' + acces_token
          resolve(insAxios(originalRequest))
        })
      })
      return retryOrigReq
    } else {
      return Promise.reject(simplifyError(error))
    }
  }
)

const subscribeTokenRefresh = (cb) => {
  refreshSubscribers.push(cb)
}

const onRefreshed = (token) => {
  refreshSubscribers.map((cb) => cb(token))
  refreshSubscribers = []
}

/**
 * Connexion
 */
export function login(username, password, rememberMe) {
  return new Promise((resolve, reject) => {
    let obj = {
      grant_type: 'password',
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET,
      username: username,
      password: password
    }

    axiosApi('oauth/v2/token', obj, 'post').then(
      (data) => {
        // Temps d'expiration
        const expire_date = Date.now() + expireInDay * 1000 * 60 * 60 * 24

        // stockage user dans redux
        store.dispatch(setUserInformations({...data.account, controllerRole: ''}))

        // stockage tokens dans redux
        store.dispatch(setTokens({ ...data, expire_date }))

        // stockage / suppression rememberMe access
        if (rememberMe) {
          store.dispatch(setRememberMe(rememberMe))
        } else {
          store.dispatch(delRememberMe())
        }

        resolve(data.account)
      },
      (error) => {
        console.log('error', error)
        reject(error)
      }
    )
  })
}

export function loginsso(code) {
  return new Promise((resolve, reject) => {
    axiosApi('completeapp', { code }, 'post').then(
      (data) => {
        // Temps d'expiration
        const expire_date = Date.now() + expireInDay * 1000 * 60 * 60 * 24

        // stockage user dans redux
        store.dispatch(setUserInformations(data.account))

        // stockage tokens dans redux
        store.dispatch(setTokens({ ...data, expire_date }))

        // for now, always set remember me to true
        store.dispatch(setRememberMe(true))

        resolve(data.account)
      },
      (error) => {
        console.log('error', error)
        reject(error)
      }
    )
  })
}

/**
 * Déconnexion
 */
export function logout() {
  return new Promise((resolve) => {
    // suppr tokens dans redux
    store.dispatch(delTokens())
    store.dispatch(delUserInformations())

    resolve(null)
  })
}

export function rehydrateStore() {
  return new Promise((resolve, reject) => {
    if (!store.getState().user.access_token) {
      return
    }
    accountData(true).catch(() => logout())
  })
}

// Remise à jour du store via API tous les 60 secondes
const rehydrateStoreInterval = () => {
  // console.log(
  //   "%c REHYDRATE STORE ",
  //   "color: #D81365; font-style: italic; font-weight: bold; background-color: #1D1E1C; padding: 2px"
  // );
  rehydrateStore()
}

// setTimeout(rehydrateStore, 1000)
setInterval(rehydrateStoreInterval, 1000 * 60 * 15)

export function fakeRequest(success = true, delay = 250) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (success) {
        resolve(true)
      } else {
        reject(false)
      }
    }, delay)
  })
}
