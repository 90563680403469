import { IonButton } from '@ionic/react'
import { updateEntrance } from 'api/entrance'
import React, { useEffect, useState } from 'react'
import { Marker } from 'react-leaflet'
import { getDistance } from 'utils/map'
import Map, { LocateMarker } from './Map'

const UpdatePositionMapModal: React.FC<{
  onDismiss: () => void
  entranceID: number
  entrancePosition: L.LatLngTuple
}> = ({ onDismiss, entrancePosition, entranceID }) => {
  const [userPosition, setUserPosition] = useState<L.LatLngTuple>([0, 0])

  const [distance, setDistance] = useState<number>(0)

  const _updateEntrance = () => {
    console.log('updating entrance position')
    updateEntrance(entranceID, {
      latitude: userPosition[0],
      longitude: userPosition[1]
    })
      .then((data: any) => {
        onDismiss()
        // TODO display success
      })
      .catch((err: any) => {
        onDismiss()
        // TODO display error
      })
  }

  useEffect(() => {
    setDistance(Math.round(getDistance(userPosition, entrancePosition)))
  }, [userPosition, entrancePosition])

  return (
    <div>
      <div className="minimap">
        <Map
          scrollWheelZoom={false}
          doubleClickZoom={false}
          dragging={false}
          zoom={17}
          minZoom={17}
          maxZoom={17}>
          <LocateMarker onUpdate={(latlng) => setUserPosition(latlng)} /> 
          {/* TODO, marker icon */}
          <Marker position={entrancePosition} />
        </Map>
      </div>
      <div className="content">
        <span>
          Souhaitez-vous mettre à jour la position de l'entrée avec vos
          coordonnées ?
        </span>
        <p>Celle-ci se situe actuellement à {distance} mètres de vous.</p>
      </div>
      <IonButton
        className="qr-modal_button"
        fill="clear"
        onClick={_updateEntrance}>
        Mettre à jour
      </IonButton>
      <IonButton
        className="qr-modal_button"
        fill="clear"
        onClick={() => onDismiss()}>
        Annuler
      </IonButton>
    </div>
  )
}

export default UpdatePositionMapModal
