import {
  IonButton
} from '@ionic/react'
import Container from 'layouts/containers/Container'
import React, { useCallback, useEffect, useState } from 'react'
import { RouteComponentProps, useHistory } from 'react-router'
import LayoutDefault from 'layouts/LayoutDefault'
import { Spot, ReportType } from 'interfaces/common'
import { getReportTypes } from 'api/reports'
import { PhotoInterface } from 'hooks/usePhotoGallery'
import ReportClose from 'components/report/closeReport'

const ReportTypeSelection: React.FC<
  RouteComponentProps<
    any,
    any,
    { spot: Spot; message: string; photos: Array<PhotoInterface>, fromPath?: string }
  >
> = ({ location }) => {
  const [error, seterror] = useState(true)
  const [errorMsg, seterrorMsg] = useState('')
  const [loading, setloading] = useState(false)
  const [reportTypes, setreportTypes] = useState<ReportType[]>([])

  const router = useHistory()

  const fetchReportTypes = useCallback(() => {
    setloading(true)
    seterror(false)
    seterrorMsg('')

    getReportTypes()
      .then((res: any) => {
        setreportTypes(res)
        console.log(res);
      })
      .catch((error: any) => {
        if (error === 'errors.error_network') {
          seterrorMsg(
            "Un problème est survenue, assurez vous d'être connecté à internet"
          )
        }
        seterror(true)
      })
      .finally(() => {
        setloading(false)
      })
  }, [])

  const goToReport = (reportType: ReportType) => {
    // Stockage des informations du type de signalement afin de les récupérer après avoir chercher un spot grâce à la barre de recherche
    const stateLocation = {...location.state, reportType}
    // localStorage.setItem("id", reportType.id.toString());
    // localStorage.setItem("name", reportType.name);
    // localStorage.setItem("picture", reportType.picture);
    // localStorage.setItem("qualification", reportType.name);
    return reportType.qualification === 'qr_code' ? router.push('/report/list', stateLocation) : router.push('/report/form', stateLocation)
  }

  useEffect(() => {
    fetchReportTypes()
  }, [fetchReportTypes])

  if (error) {
    return (
      <LayoutDefault>
        <Container center>
          <p className="ion-text-center">
            {errorMsg || 'Une erreur a eu lieu'}
          </p>
          <IonButton onClick={fetchReportTypes}>Réessayer</IonButton>
        </Container>
      </LayoutDefault>
    )
  }

  return (
    <LayoutDefault>
      <Container paddingTop loading={loading}>
        <ReportClose fromPath={location.state?.fromPath} />
        <h3>Sélectionnez le type de signalement</h3>
        <ul className="report-type-list">
          {reportTypes.map((reportType, index: number) => (
            <li
              key={reportType.id}
              onClick={() => goToReport(reportType)}
              style={{backgroundImage: 'url('+process.env.REACT_APP_BACKEND + '/' + reportType.picture+')'}}
            >
              <span>
                {reportType.name}
              </span>
            </li>
          ))}
        </ul>
      </Container>
    </LayoutDefault>
  )
}

export default ReportTypeSelection
