import { axiosApi } from './helper'
import store from '../stores/store'
import { setUserInformations } from '../stores/actions/user'

// Email verification for new password request
export function forgotPassword(mail) {
  let obj = {
    email: mail
  }

  return axiosApi('account/forgotPassword', obj, 'post')
}

export function confirmCode(mail, code) {
  let obj = {
    email: mail,
    code: code
  }

  return axiosApi('account/checkRecoveryCode', obj, 'post')
}

// New password
export function NewPasswords(obj, token) {
  return axiosApi('account', obj, 'put', { Authorization: `Bearer ${token}` })
}

// Reset password
export function resetNewPassword(email, password, code) {
  let obj = {
    email: email,
    password: password,
    code: code
  }

  return axiosApi('account/changePassword', obj, 'post')
}

export function aggregates() {
  return axiosApi('aggregates', {}, 'get')
}

export function accountData(force = null) {
  return new Promise((resolve, reject) => {
    // RECUPERATION ACCOUNT REDUX
    let user = store.getState().user
    let isUpdate = Date.now() - user.lastUpdated < 3600
    if (isUpdate && !force) {
      resolve(user)
    } else {
      axiosApi('account', {}, 'get').then(
        (data) => {
          store.dispatch(setUserInformations(data))
          resolve(data)
        },
        (error) => {
          reject(error)
        }
      )
    }
  })
}

export function refreshAccountData() {
  return new Promise((resolve, reject) => {
    axiosApi('account', {}, 'get').then(
      (data) => {
        store.dispatch(setUserInformations(data))
        resolve(data)
      },
      (error) => {
        reject(error)
      }
    )
  })
}

export function sendAccount(obj) {
  return axiosApi('account', obj, 'put')
}

export function agree() {
  return axiosApi('agree', {}, 'post')
}

export function askSuppression() {
  return axiosApi('askSuppression', {}, 'post')
}
