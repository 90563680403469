import validatejs from 'validate.js';


const validation = {
  email: {
    presence: {
      allowEmpty: false,
      message: "^Veuillez entrer une adresse email"
    },

    email: {
      message: "^Veuillez entrer une adresse email valide"
    }
  },

  password: {
    presence: {
      allowEmpty: false,
      message: "^Veuillez entrer un mot de passe"
    },

    format: {
      pattern: "^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9@#$%^&+*!=]).*$",
      message: "^Votre mot de passe doit contenir au moins une majuscule, une minuscule et un chiffre ou un caractère spécial"
    },

    length: {
      minimum: 8,
      message: "^Votre mot de passe doit contenir au minimum 8 caractères"
    },
  },

  passwordLength: {
    length: {
      minimum: 8,
      message: "^Votre mot de passe doit contenir au minimum 8 caractères"
    },
  },

  passwordMin: {
    format: {
      pattern: "(?=.*[a-z]).*$",
    },
  },

  passwordMaj: {
    format: {
      pattern: "(?=.*[A-Z]).*$",
    },
  },

  passwordSpecial: {
    format: {
      pattern: "(?=.*[0-9@#$%^&+*!=]).*$"
    },
  },

  lastname: {
    presence: {
      allowEmpty: false,
      message: "^Veuillez renseigner votre nom"
    },

    format: {
      pattern: "^(?=.{1,40}$)[A-Za-zÀ-ÖØ-öø-ÿ]+(?:[-' ][A-Za-zÀ-ÖØ-öø-ÿ]+)*$",
      message: "^Caractères spéciaux non autorisés"
    },

    length: {
      maximum: 30,
      minimum: 2,
      message: "^Votre nom ne contient pas la quantité requise de caractère"
    },
  },

  profession: {
    presence: {
      allowEmpty: false,
      message: "^Veuillez renseigner votre entreprise"
    },

    format: {
      pattern: "^(?=.{1,40}$)[A-Za-zÀ-ÖØ-öø-ÿ]+(?:[-' ][A-Za-zÀ-ÖØ-öø-ÿ]+)*$",
      message: "^Caractères spéciaux non autorisés"
    },

    length: {
      maximum: 30,
      minimum: 2,
      message: "^Votre entreprise ne contient pas la quantité requise de caractère"
    },
  },

  firstname: {
    presence: {
      allowEmpty: false,
      message: "^Veuillez renseigner votre prénom"
    },

    format: {
      pattern: "^(?=.{1,40}$)[A-Za-zÀ-ÖØ-öø-ÿ]+(?:[-' ][A-Za-zÀ-ÖØ-öø-ÿ]+)*$",
      message: "^Caractères spéciaux non autorisés"
    },

    length: {
      maximum: 30,
      minimum: 2,
      message: "^Votre prénom ne contient pas la quantité requise de caractère"
    },
  },

  phone: {
    presence: {
      allowEmpty: false,
      message: `^Téléphone requis`
    },

    length: {
      is: 10,
      message: `^Numéro non valide`
    },

    numericality: {
      onlyInteger: true,
      message: `^Numéro non valide`
    },
  },

  company: {
    presence: {
      allowEmpty: false,
      message: "^Veuillez renseigner votre poste"
    },

    format: {
      pattern: "^(?=.{1,40}$)[A-Za-zÀ-ÖØ-öø-ÿ]+(?:[-' ][A-Za-zÀ-ÖØ-öø-ÿ]+)*$",
      message: "^Caractères spéciaux non autorisés"
    },

    length: {
      maximum: 30,
      minimum: 2,
      message: "^Votre poste ne contient pas la quantité requise de caractère"
    },
  }
}

/**
 * Valider un champ input
 * @param {string} fieldName Le type de champ
 * @param {any} value Valeur du champ
 */
export default function Validate(fieldName, value) {
  // Validate.js validates your values as an object
  // e.g. var formValues = {email: 'email@example.com'}
  var formValues = {}
  formValues[fieldName] = value

  // Creates a temporary form with the validation fields
  var formFields = {}
  formFields[fieldName] = validation[fieldName]

  // The formValues and validated against the formFields
  // the variable result hold the error messages of the field
  const result = validatejs(formValues, formFields)

  // If there is an error message, return it!
  if (result) {
    // Return only the field error message if there are multiple
    return result[fieldName][0]
  }

  return null
}
