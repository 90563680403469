export function setLightTheme() {
  return {
    type: 'SET_THEME',
    data: false
  }
}

export function setDarkTheme() {
  return {
    type: 'SET_THEME',
    data: true
  }
}
