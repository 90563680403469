//import { Geolocation, GeolocationPosition } from '@capacitor/core'
import { Geolocation } from '@capacitor/geolocation'
import { scanQrcode } from 'api/callout'
import { History } from 'history'

export const scanHandler = async (data: string): Promise<any> => {
  const position = await getPosition()
  const code = data.substring(18)

  return new Promise((resolve, reject) => {
    if (!position) {
      return reject(
        'Impossible de récupérer votre position, assurez vous que la localisation de votre appareil soit activée'
      )
    }

    const obj = {
      qrCode: code,
      latitude: position.coords.latitude,
      longitude: position.coords.longitude
    }

    // const obj = {
    //   qrCode: code,
    //   latitude: 50,
    //   longitude: 1
    // }

    scanQrcode(obj)
      .then((data: any) => {
        // Retourne l'ID du spot
        resolve(data)
      })
      .catch((error: any) => {
        console.log(error)
        // TODO retourner des messages d'erreur pour l'affichage
        if (error === 'errors.error_network' || error?.data) {
          reject(error)
        }
        let erreur = error?.data?.message || ''
        reject(erreur)
      })
  })
}

export const getPosition = async () => {
  try {
    const position = await Geolocation.getCurrentPosition()
    return position
  } catch (error) {
    console.log('Error getting location', error)
    return null
  }
}

export const redirectionHandler = (router: History, spotID: any) => {
  router.push(`/spot/${spotID}/job-selection`)
  // Redirect en fonction du rôle utilisateur et du statut de l'intervention
  // switch (role) {
  //   // case CONTROLEUR:
  //   //   router.push(`/${role}/${callout.callout}/scoring`)
  //   //   break

  //   default:
  //     if (!callout.endCallOut) {
  //       router.push(`/${role}/${callout.callout}`)
  //     } else {
  //       router.push(`/${role}/${callout.callout}/scoring`)
  //     }
  //     break
  // }
}
