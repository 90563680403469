import { IonList, IonListHeader, IonLabel, IonItem } from '@ionic/react'
import ReportClose from 'components/report/closeReport'
import LayoutDefault from 'layouts/LayoutDefault'
import Container from 'layouts/containers/Container'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { RootState } from 'stores/reducers'

function ReportProfessionSelection({
  onSelect
}: {
  onSelect: (profession?: number) => void
}) {
  const location = useLocation<{ fromPath: string }>()
  const professions = useSelector((state: RootState) => state.user.professions)

  useEffect(() => {
    if (professions.length === 0) {
      onSelect()
    }

    if (professions.length === 1) {
      onSelect(professions[0].id)
    }
  }, [professions])

  return (
    <LayoutDefault>
      <Container paddingTop>
        <ReportClose fromPath={location.state?.fromPath} />
        <IonList lines="full">
          <IonListHeader mode="ios">
            <IonLabel>Sélectionnez un métier parmis ceux proposés</IonLabel>
          </IonListHeader>

          {professions.map((profession: any, index: number) => (
            <IonItem
              button
              key={profession.id}
              lines={index >= professions.length - 1 ? 'none' : undefined}
              onClick={() => onSelect(profession.id)}>
              <IonLabel>{profession.name}</IonLabel>
            </IonItem>
          ))}
        </IonList>
      </Container>
    </LayoutDefault>
  )
}

export default ReportProfessionSelection
