import { axiosApi } from './helper'

// Get spots by groupe
export function groupesSpots(obj) {
  return axiosApi('spots', obj, 'get')
}

// Get spot
export function getSpot(id) {
  return axiosApi('spots/' + id, {}, 'get')
}

// Get spot
export function getSpotByQrcode(qrcode) {
  return axiosApi('spotsByQrcode', { qrcode }, 'get')
}

// Get spots
export function getSpots(obj) {
  return axiosApi('searchSpots', obj, 'get')
}

// Get a spot if there is any ongoing callout for current user
export function getOngoingSpot() {
  return axiosApi('ongoingSpot', {}, 'get')
}

// Add spot in groupe
export function addSpot(obj) {
  return axiosApi('spots', obj, 'post')
}

// Del spot in groupe
export function deleteSpot(id) {
  return axiosApi('spots/' + id, {}, 'delete')
}

// Associate controller in spot
export function associateController(id, obj) {
  return axiosApi('spots/' + id + '/associate', obj, 'post')
}

// Deassociate controller in spot
export function deassociateController(id) {
  return axiosApi('spots/' + id + '/deassociate', {}, 'post')
}

// Get Jobs available on a spot
export function spotJobs(id) {
  return axiosApi('spots/' + id + '/jobs', {}, 'get')
}
