import './Admin.scss'
import React, { useCallback, useEffect, useState } from 'react'
import LayoutDefault from '../../layouts/LayoutDefault'
import {
  IonButton,
  IonCard,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonRow,
  IonText,
  useIonModal
} from '@ionic/react'
import { search, warningOutline } from 'ionicons/icons'
import Container from 'layouts/containers/Container'
import { getSpot } from 'api/spots'
import ModalQrCode from 'components/admin/ModalQrCode'
import { Controller, ReportType, Spot } from 'interfaces/common'
import { getEntranceBySpot } from 'api/entrance'
import { useHistory } from 'react-router'
import UpdatePositionMapModal from 'components/map/UpdatePositionMapModal'
import BackButton from './backButton'

const AdminSpot: React.FC = (props: any) => {
  const [loading, setloading] = useState(true)
  const [error, seterror] = useState(false)
  const [errormsg, seterrormsg] = useState('')
  const [qrmodal, setqrmodal] = useState(false)
  const [controller, setcontroller] = useState<Controller | null>(null)
  const [entrancePosition, setEntrancePosition] = useState<L.LatLngTuple>([
    0, 0
  ])
  const [entranceID, setEntranceID] = useState(0)
  const [spot, setspot] = useState<Spot>({
    id: 0,
    name: '',
    description: '',
    code: '',
    controller: {
      id: 0,
      qrCode: '',
      qrCodeImage: ''
    }
  })

  const { spotID } = props.match.params
  const router = useHistory()
  const startUrl = props.match.url.split('/')[1]

  const fetchSpot = useCallback(() => {
    setloading(true)
    seterror(false)
    seterrormsg('')
    getSpot(spotID)
      .then((data: any) => {
        setspot(data)
      })
      .catch((error: any) => {
        console.log(error)
        if (error === 'errors.error_network') {
          seterrormsg(
            "Un problème est survenue, assurez vous d'être connecté à internet"
          )
        }
        seterror(true)
      })
      .finally(() => {
        setloading(false)
      })
  }, [spotID])

  const fetchEntrance = useCallback(() => {
    getEntranceBySpot(spotID)
      .then((data: any) => {
        setEntrancePosition([data.latitude, data.longitude])
        setEntranceID(data.id)
      })
      .catch((err: any) => {
        console.log(err)
      })
  }, [spotID])

  useEffect(() => {
    setcontroller(null)
    setqrmodal(false)
    if (props.location?.state?.qrcode) {
      // On vient de scanner un QR code à associer
      const qrcode = props.location.state.qrcode
      setcontroller({
        id: 0,
        qrCodeImage: `${qrcode}.png`,
        qrCode: qrcode,
        changing: true
      })
      setqrmodal(true)
    }

    if (props.location?.state?.updated) {
      present({
        cssClass: 'auto-height update-pos-modal'
      })
    }

    fetchSpot()
    fetchEntrance()
  }, [fetchSpot, props.location, spotID, fetchEntrance])

  // Update position modal logic
  const handleDismiss = () => {
    dismiss()
    router.replace(props.location.pathname, {})
  }

  const [present, dismiss] = useIonModal(UpdatePositionMapModal, {
    onDismiss: handleDismiss,
    entrancePosition,
    entranceID
  })

  const selectSpot = () => {
    router.push('/report/form', { ...props.location.state, spot })
  }

  const handleClick = () => {
    if (startUrl === 'report') {
      selectSpot()
    }
  }

  if (error) {
    return (
      <LayoutDefault>
        <Container center>
          <p className="ion-text-center">
            {errormsg !== '' ? errormsg : 'Une erreur est survenue'}
          </p>
          <IonButton onClick={fetchSpot}>Réessayer</IonButton>
        </Container>
      </LayoutDefault>
    )
  }

  return (
    <LayoutDefault>
      <Container paddingTop loading={loading}>
      <BackButton url="/" />

        <IonText class="title-admin" color="dark">
          Informations du spot
        </IonText>

        <IonCard
          class="thumbnail"
          color="light"
          mode="ios"
          onClick={handleClick}>
          <div className="description-content border">
            <IonText color="dark" class="label-admin">
              Code :
            </IonText>
            <IonText class="description" color="dark">
              {spot.code}
            </IonText>
          </div>

          <div className="description-content border">
            <IonText color="dark" class="label-admin">
              Nom :
            </IonText>
            <IonText class="description" color="dark">
              {spot.name}
            </IonText>
          </div>

          <div className="description-content">
            <IonText color="dark" class="label-admin">
              Description :
            </IonText>
            <IonText class="description" color="dark">
              {spot.description}
            </IonText>
          </div>
        </IonCard>

        {startUrl !== 'report' && (
          <>
            <IonGrid className="controler-header ion-no-padding">
              <IonRow class="ion-justify-content-between ion-align-items-center">
                <IonCol size="9">
                  <IonText class="title-admin" color="dark">
                    Contrôleur associé
                  </IonText>
                </IonCol>
              </IonRow>
            </IonGrid>

            <div className="controler-container">
              {spot.controller ? (
                <IonItem
                  class="thumbnail"
                  lines="none"
                  detail
                  button
                  key={spot.controller.id + spot.controller.qrCode}
                  onClick={() => setqrmodal(true)}>
                  <IonText>{spot.controller.qrCode}</IonText>
                  <IonIcon
                    icon={search}
                    size="small"
                    color="dark"
                    slot="end"></IonIcon>
                </IonItem>
              ) : (
                <IonItem
                  class="thumbnail"
                  lines="none"
                  detail
                  button
                  onClick={() => setqrmodal(true)}>
                  <IonIcon
                    icon={warningOutline}
                    color="danger"
                    class="danger"></IonIcon>
                  <IonText>Aucun contrôleur associé</IonText>
                </IonItem>
              )}
            </div>
          </>
        )}

        {/* MODALE DU QR CODE */}
        <ModalQrCode
          spotID={spotID}
          match={props.match}
          setqrmodal={setqrmodal}
          controller={controller || spot.controller}
          qrmodal={qrmodal}
          refreshSpot={setspot}
        />
      </Container>
    </LayoutDefault>
  )
}

export default AdminSpot
