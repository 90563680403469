import { IonButton, IonCard, IonCardSubtitle, IonText, IonCardContent, IonCardHeader, IonCardTitle} from '@ionic/react'
import Container from 'layouts/containers/Container'
import React, { useCallback, useEffect, useState } from 'react'
import { RouteComponentProps, useHistory } from 'react-router'
import LayoutSearch from 'layouts/LayoutSearch'
import { Spot, ReportType } from 'interfaces/common'
import { getSpots } from 'api/spots'
import { PhotoInterface } from 'hooks/usePhotoGallery'
import { getPosition } from 'utils/callout'
import ReportClose from 'components/report/closeReport'
import LayoutDefault from 'layouts/LayoutDefault'
import BackButton from 'pages/admin/backButton'
import { getEntranceByPosition } from 'api/entrance'
import { Entrance } from 'interfaces/common'
import './styles.scss'

const SpotSelection: React.FC<
  RouteComponentProps<
    any,
    any,
    {
      reportType: ReportType
      spot: Spot
      message?: string
      photos?: Array<PhotoInterface>
      fromPath?: string
    }
  >
> = ({ location }) => {
  const [error, seterror] = useState(true)
  const [errorMsg, setErrorMsg] = useState('')
  const [loading, setloading] = useState(false)
  // const [spots, setspots] = useState<Spot[]>([])
  const [nearEntries, setNearEntries] = useState<Entrance[]>([])
  const router = useHistory()

  const fetchSpots = useCallback(async (e: any) => {
    seterror(false)
    setErrorMsg('')
    setloading(true)

    // const position = await getPosition()
    // if (!position) {
    //   seterror(true)
    //   setErrorMsg(
    //     'Impossible de récupérer votre position, assurez vous que la localisation de votre appareil soit activée'
    //   )
    //   return
    // }

    // const obj = {
    //   search: e && e.detail ? e.detail.value : '',
    //   latitude: position.coords.latitude,
    //   longitude: position.coords.longitude
    // }

    // getSpots(obj)
    //   .then((results: any) => {
    //     if (results.length === 0) {
    //       seterror(true)
    //       setErrorMsg('Aucun spot trouvé dans votre périmetre')
    //     } else {
    //       setspots(results)
    //     }
    //   })
    //   .catch((error: any) => {
    //     if (error === 'errors.error_network') {
    //       setErrorMsg(
    //         "Un problème est survenue, assurez vous d'être connecté à internet"
    //       )
    //     }
    //     seterror(true)
    //   })
    //   .finally(() => {
    //     setloading(false)
    //   })
  }, [])

  const searchNearEntrance = async() => {
    setloading(true)
    seterror(false)
    const position = await getPosition()
    const lat = position?.coords.latitude
    const long = position?.coords.longitude
    if (lat != null && long != null) {
      getEntranceByPosition(lat, long)
        .then((results: any) => {
          setNearEntries(results)
        })
        .catch((err: any) => {
          console.log(err)
          if (err === 'errors.error_network') {
            setErrorMsg(
              "Un problème est survenue, assurez vous d'être connecté à internet"
              )
            }
            seterror(true)
          })
          .finally(() => {
            setloading(false)
          })
        }
        else {
          setErrorMsg(
            "Un problème est survenue, assurez vous d'avoir partagé votre géolocalisation"
            )
          }
        }
        
        const selectEntrance = (entrance: Entrance) => {
          const stateLocation = { ...location.state, entrance }
          router.push(`/report/entrance/${entrance.id}`, stateLocation)
        }
        
        useEffect(() => {
          fetchSpots(null)
          searchNearEntrance()
        }, [fetchSpots])
        
        if (error) {
          return (
            <LayoutDefault>
        <Container center>
          <ReportClose fromPath={location.state?.fromPath} />
          <p className="ion-text-center">
            {errorMsg !== '' ? errorMsg : 'Une erreur a eu lieu'}
          </p>
          <IonButton onClick={fetchSpots}>Réessayer</IonButton>
        </Container>
      </LayoutDefault>
    )
  }

  const DefaultSpotsList = () => {
    return (
      <>
        <BackButton url="/" />
        <h3 className="chooseSpotTitle">
          Sélectionnez une entrées proches
        </h3>
        {nearEntries[0] ? 
          nearEntries.map((entrance: any) => (
            <IonCard
            {...console.log(entrance)}
            key={entrance.id}
            className="ion-text-center rounder"
            onClick={() => selectEntrance(entrance)}
            // routerLink={`/report/entrance/${entrance.id}`}
            >
            <IonCardHeader>
              <IonCardTitle>{entrance.name}</IonCardTitle>
              <IonCardSubtitle>{entrance.commune}</IonCardSubtitle>
            </IonCardHeader>
            <IonCardContent>
              <IonText color="dark">
                Code de l'entrée : {entrance.code}
                <br />
                Située à{' '}
                {entrance.distance <= 10
                  ? 'côté de vous'
                  : Math.round(entrance.distance) + ' mètres de vous'}
              </IonText>
            </IonCardContent>
          </IonCard>
        ))
      : 
      <div>Aucune entrées proches</div>
      }
      </>
    )
  }

  return (
    <LayoutSearch>
      <Container paddingTop loading={loading}>
        <ReportClose fromPath={location.state?.fromPath} />
        <DefaultSpotsList />
      </Container>
    </LayoutSearch>
  )
}

export default SpotSelection
