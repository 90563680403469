import React from 'react'
import { Route, RouteComponentProps } from 'react-router-dom'
import { IonRouterOutlet } from '@ionic/react'
import Login from './Login'
import Signup from './Signup'
import SSO from './SSO'
import GuestMiddleware from '../../middlewares/GuestMiddleware'
import ForgotPassword from './ForgotPassword'

const GuestPages: React.FC<RouteComponentProps> = ({ match }) => {
  return (
    <IonRouterOutlet>
      <GuestMiddleware>
        {/* TODO App entry point */}
        <Route exact path={`${match.url}`} component={Login} />
        <Route exact path={`${match.url}/login`} component={Login} />
        <Route  exact path={`${match.url}/signup`} component={Signup}/>
        <Route exact path={`${match.url}/complete`} component={SSO} />
        <Route
          exact
          path={`${match.url}/forgot-password`}
          component={ForgotPassword}
        />
      </GuestMiddleware>
    </IonRouterOutlet>
  )
}

export default GuestPages
