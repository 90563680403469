import { IonSpinner } from '@ionic/react'
import React, { ReactNode } from 'react'
import './container.scss'

interface ContainerProps {
  children: ReactNode
  paddingTop?: boolean
  paddingBottom?: boolean
  center?: boolean
  loading?: boolean
  small?: boolean
  noPadding?: boolean
}

const Container: React.FC<ContainerProps> = ({
  children,
  paddingTop,
  paddingBottom,
  center,
  loading,
  small,
  noPadding
}) => {
  let classname = 'container-default'

  if (paddingTop) {
    classname += ' container-default--padding-top'
  }

  if (paddingBottom) {
    classname += ' container-default--padding-bottom'
  }

  if (noPadding) {
    classname += ' container-default--no-padding'
  }

  if (small) {
    classname += ' container-default--small'
  }

  if (center || loading) {
    classname += ' container-default--center'
  }

  if (loading) {
    return (
      <div className="container-default container-default--center">
        <IonSpinner />
      </div>
    )
  }

  return (
    <div className={classname}>
      <div>{children}</div>
    </div>
  )
}

export default Container
