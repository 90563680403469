import React from 'react'
import Validate from '../../assets/validation.js';
import './ValidatePassword.scss'

const ValidatePassword = ({
  value,
  activeColor = "#b7d406",
  styles = {},
  t,
  children
}:any) => {
  const checkLength = !Validate('passwordLength', value)
  const checkMaj = !Validate('passwordMaj', value)
  const checkMin = !Validate('passwordMin', value)
  const checkSpecial = !Validate('passwordSpecial', value)

  return (
    <div>
      <div>
        {children}
      </div>
      <ul className="verif_mdp">
        <li style={{ ...styles.item, color: (checkLength) ? activeColor : '#707070' }}>
          - 8 caractères minimum
        </li>
        <li style={{ ...styles.item, color: (checkMaj) ? activeColor : '#707070' }}>
          - au moins une majuscule
        </li>
        <li style={{ ...styles.item, color: (checkMin) ? activeColor : '#707070' }}>
          - au moins une minuscule
        </li>
        <li style={{ ...styles.item, color: (checkSpecial) ? activeColor : '#707070' }}>
          - au moins un chiffre ou un caractère spécial
        </li>
      </ul>
    </div>
  )
}

export default ValidatePassword