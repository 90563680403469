import React, { useEffect } from 'react'
import { Redirect, Route, RouteComponentProps } from 'react-router-dom'
import { IonRouterOutlet } from '@ionic/react'
import AdminIndex from '.'
import RoleMiddleware from '../../middlewares/RoleMiddleware'
import { ADMIN_QR, CONTROLLER } from '../../utils/const'
import AuthMiddleware from '../../middlewares/AuthMiddleware'
import AdminEntrance from './entrance'
import AdminSpot from './spot'
import AdminGroup from './group'
import AdminBuilding from './building'
import QrScanner from 'pages/misc/QrScanner'
import { setControllerRole } from 'stores/actions/user'
import store from 'stores/store'

const AdminPages: React.FC<RouteComponentProps> = ({ match }) => {
  // Si on est ici c'est forcément que l'on veut aller sur une route admin
  useEffect(() => {
    store.dispatch(setControllerRole(ADMIN_QR))
  }, [])

  return (
    <IonRouterOutlet>
      <AuthMiddleware>
        <RoleMiddleware roles={[ADMIN_QR, CONTROLLER]}>
          <Route
            exact
            path={`${match.url}/entrance/:entranceID`}
            component={AdminEntrance}
          />
          <Route
            exact
            path={`${match.url}/spots/:spotID`}
            component={AdminSpot}
          />
          <Route
            exact
            path={`${match.url}/spots/:spotID/scan`}
            component={QrScanner}
          />
          <Route
            exact
            path={`${match.url}/groups/:groupID`}
            component={AdminGroup}
          />
          <Route
            exact
            path={`${match.url}/buildings/:buildingID`}
            component={AdminBuilding}
          />
          <Route exact path="/admin-QR">
            <Redirect to={`/admin-QR/list`} />
          </Route>
          <Route path={match.url + '/list'} component={AdminIndex} />
          <Route path={match.url + '/map'} component={AdminIndex} />
        </RoleMiddleware>
      </AuthMiddleware>
    </IonRouterOutlet>
  )
}

export default AdminPages
