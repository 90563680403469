import React, { useState } from 'react'
import { closeCircleOutline, qrCodeOutline, trashOutline } from 'ionicons/icons'
import {
  IonButton,
  IonIcon,
  IonImg,
  IonModal,
  IonSpinner,
  IonText
} from '@ionic/react'
import { associateController, deassociateController } from 'api/spots'
import { useHistory } from 'react-router'
import { Controller } from 'interfaces/common'

interface ModalQrCodeProps {
  controller: Controller
  setqrmodal: any
  qrmodal: boolean
  refreshSpot: Function
  match: any
  spotID: any
}

const ModalQrCode: React.FC<ModalQrCodeProps> = ({
  spotID,
  controller,
  setqrmodal,
  qrmodal,
  refreshSpot,
  match
}) => {
  const [error, seterror] = useState(false)
  const [errormsg, seterrormsg] = useState('')
  const [loading, setloading] = useState(false)
  const router = useHistory()

  const delQrcode = () => {
    seterror(false)
    setloading(true)
    seterrormsg('')
    deassociateController(spotID)
      .then((data: any) => {
        closeModal()
        refreshSpot(data.spot)
      })
      .catch((error: any) => {
        if (error === 'errors.error_network') {
          seterrormsg(
            "Un problème est survenue, assurez vous d'être connecté à internet"
          )
        }
        seterror(true)
      })
  }

  const goToScan = () => {
    router.replace(
      `/admin-QR/spots/${match.params.spotID}/scan`
    )
  }

  const associate = () => {
    setloading(true)
    seterror(false)
    seterrormsg('')
    const qrcode = controller.qrCode
    associateController(spotID, { qrCode: qrcode })
      .then(() => {
        // setqrmodal(false)
        // refreshSpot(data.spot)
        router.replace(
          `/admin-QR/spots/${match.params.spotID}`,
          {
            updated: true
          }
        )
      })
      .catch((error: any) => {
        console.log(error.data)
        if (error === 'errors.error_network') {
          seterrormsg(
            "Un problème est survenue, assurez vous d'être connecté à internet"
          )
        } else if (typeof error.data !== 'string') {
          seterrormsg('')
        } else {
          // seterrormsg(error.data)
          if (error.data === 'This QR Code is already associated with a Spot') {
            seterrormsg('Ce QR code est déjà associé à un autre Spot')
          } else if (error.data === 'No corresponding QR Code') {
            seterrormsg("Ce QR Code n'existe pas dans notre base de donnée")
          } else if (
            error.data === 'This spot is already associated with a QR Code'
          ) {
            seterrormsg('Ce spot est déjà associé à un QR Code')
          } else if (error.data === 'No corresponding spot') {
            seterrormsg("Ce spot n'existe pas dans notre base de donnée")
          }
        }
        seterror(true)
      })
      .finally(() => {
        setloading(false)
      })
  }

  const closeModal = () => {
    setqrmodal(false)
    router.replace(
      `/admin-QR/spots/${match.params.spotID}`,
      {}
    )
  }

  const displayModal = () => {
    if (error) {
      return (
        <div className="qr-modal-text">
          <IonText color="danger">
            {errormsg !== '' ? errormsg : 'Une erreur a eu lieu'}
          </IonText>
        </div>
      )
    }

    if (controller?.changing) {
      return (
        <div
          key={controller.id + controller.qrCode}
          className="qr-modal-content">
          <div className="qr-modal_header">
            <IonText>{controller.qrCode}</IonText>
          </div>
          <div className="qr-modal_img">
            <IonImg
              src={
                process.env.REACT_APP_BACKEND + '/qr/' + controller.qrCodeImage
              }
            />
          </div>
          <IonButton
            className="qr-modal_button"
            onClick={() => associate()}
            fill="clear">
            <IonIcon icon={qrCodeOutline} color="success" size="large" />
            <IonText color="success">Valider ce QR code</IonText>
          </IonButton>
        </div>
      )
    }

    if (controller && controller.id !== 0) {
      return (
        <div
          key={controller.id + controller.qrCode}
          className="qr-modal-content">
          <div className="qr-modal_header">
            <IonText>{controller.qrCode}</IonText>
          </div>
          <div className="qr-modal_img">
            <IonImg
              src={
                process.env.REACT_APP_BACKEND + '/qr/' + controller.qrCodeImage
              }
            />
          </div>
          <IonButton
            className="qr-modal_button"
            onClick={() => delQrcode()}
            fill="clear">
            <IonIcon icon={trashOutline} color="danger" size="large" />
            <IonText color="danger">Dissocier un QR code</IonText>
          </IonButton>
        </div>
      )
    }

    return (
      <div className="qr-modal-content">
        <div className="qr-modal_header">
          <IonText>Aucun QR code associé</IonText>
        </div>
        <div className="qr-modal_img">
          <IonImg src="/assets/images/no-qr.svg" />
        </div>

        <IonButton
          className="qr-modal_button"
          onClick={() => goToScan()}
          fill="clear">
          <IonIcon icon={qrCodeOutline} color="secondary" size="large" />
          <IonText color="secondary">Associer un QR code</IonText>
        </IonButton>
      </div>
    )
  }

  return (
    <IonModal
      isOpen={qrmodal}
      cssClass="qr-modal ion-text-center"
      onDidDismiss={() => setqrmodal(false)}>
      <IonIcon
        icon={closeCircleOutline}
        color="white"
        onClick={() => closeModal()}
        class="close-modal"></IonIcon>
      {loading ? (
        <div className="container-default container-default--center">
          <IonSpinner />
        </div>
      ) : (
        displayModal()
      )}
    </IonModal>
  )
}

export default ModalQrCode
