import React from 'react'
import { Route, RouteComponentProps } from 'react-router-dom'
import { IonRouterOutlet } from '@ionic/react'
import AuthMiddleware from 'middlewares/AuthMiddleware'
import Search from './Search'

const SearchRouter: React.FC<RouteComponentProps> = ({ match }) => {
  return (
    <IonRouterOutlet>
      <AuthMiddleware>
        <Route exact path={match.url} component={Search} />
      </AuthMiddleware>
    </IonRouterOutlet>
  )
}

export default SearchRouter
