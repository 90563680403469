import {
  IonItem,
  IonButton,
  IonBadge,
  IonLabel,
  IonToolbar,
  IonButtons,
  IonBackButton
} from '@ionic/react'
import { PhotoInterface } from 'hooks/usePhotoGallery'
import { Building, Entrance, Group, ReportType, Spot } from 'interfaces/common'
import BackButton from 'pages/admin/backButton'
import React, { useState } from 'react'
import { BiTargetLock } from 'react-icons/bi'
import { FaBuilding, FaDoorClosed } from 'react-icons/fa'
import { HiUserGroup } from 'react-icons/hi'
import { useHistory } from 'react-router'

export type Results = {
  groups: {
    total: number
    data: Group[]
  }
  buildings: {
    total: number
    data: Building[]
  }
  entrances: {
    total: number
    data: Entrance[]
  }
  spots: {
    total: number
    data: Spot[]
  }
}

type Category = 'group' | 'building' | 'entrance' | 'spot'

type SearchData = {
  results?: Results
  url: string
  location?: {
    reportType: ReportType
    spot: Spot
    message?: string
    photos?: Array<PhotoInterface>
    fromPath?: string
  }
}

type SearchContent = {
  category: Category
  results?: Results
  url: string
  location?: {
    reportType: ReportType
    spot: Spot
    message?: string
    photos?: Array<PhotoInterface>
    fromPath?: string
  }
}

const SearchContent = ({ category, results, url, location }: SearchContent) => {
  const history = useHistory()

  const goTo = (path: string) => {
    history.push(path, location)
  }

  switch (category) {
    case 'building':
      return (
        <>
          {results?.buildings.data.map((build) => (
            <IonItem
              className="list-item"
              key={build.id}
              onClick={() => goTo(`/${url}/buildings/${build.id}`)}
              //   routerLink={`/${url}/buildings/${build.id}`}
              lines="none"
              detail
              button>
              <div className="left_part">
                <div className="name">
                  {build.name} - {build.code}
                </div>
              </div>
            </IonItem>
          ))}
        </>
      )

    case 'entrance':
      return (
        <>
          {results?.entrances.data.map((entrance) => (
            <IonItem
              lines="none"
              detail
              button
              className="list-item"
              key={entrance.id}
              onClick={() => goTo(`/${url}/entrance/${entrance.id}`)}
              //   routerLink={`/${url}/entrance/${entrance.id}`}
            >
              <div className="left_part">
                <div className="name">
                  {entrance.name} {entrance.zipcode} {entrance.commune} -{' '}
                  {entrance.code}
                </div>
              </div>
            </IonItem>
          ))}
        </>
      )

    case 'spot':
      return (
        <>
          {results?.spots.data.map((spot) => (
            <IonItem
              className="list-item"
              lines="none"
              detail
              button
              key={spot.id}
              //   routerLink={`/${url}/spots/${spot.id}`}
              onClick={() => goTo(`/${url}/spots/${spot.id}`)}>
              <div className="left_part">
                <div className="name">
                  {spot.name} - {spot.code}
                </div>
              </div>
            </IonItem>
          ))}
        </>
      )

    case 'group':
    default:
      return (
        <>
          {results?.groups.data.map((group) => (
            <IonItem
              key={group.id}
              className="list-item"
              //   routerLink={`/${url}/groups/${group.id}`}
              onClick={() => goTo(`/${url}/groups/${group.id}`)}
              lines="none"
              detail
              button>
              <div className="left_part">
                <div className="name">
                  {group.name} - {group.code}
                </div>
              </div>
            </IonItem>
          ))}
        </>
      )
  }
}

export const SearchTabs: React.FC<SearchData> = ({
  results,
  url,
  location
}) => {
  const [category, setCategory] = useState<Category>('group')
  return (
    <div className="search">
      <div className="tab ion-padding-bottom">
        <IonButton
          onClick={() => setCategory('group')}
          fill={category === 'group' ? 'solid' : 'clear'}>
          <div className="in-button">
            <IonBadge>{results?.groups.total}</IonBadge>
            <HiUserGroup size={24} />
            <IonLabel className="tab-label">Groupes</IonLabel>
          </div>
        </IonButton>
        <IonButton
          onClick={() => setCategory('building')}
          fill={category === 'building' ? 'solid' : 'clear'}>
          <div className="in-button">
            <IonBadge>{results?.buildings.total}</IonBadge>
            <FaBuilding size={24} />
            <IonLabel className="tab-label">Bâtiments</IonLabel>
          </div>
        </IonButton>
        <IonButton
          onClick={() => setCategory('entrance')}
          fill={category === 'entrance' ? 'solid' : 'clear'}>
          <div className="in-button">
            <IonBadge>{results?.entrances.total}</IonBadge>
            <FaDoorClosed size={24} />
            <IonLabel className="tab-label">Entrées</IonLabel>
          </div>
        </IonButton>
        <IonButton
          onClick={() => setCategory('spot')}
          fill={category === 'spot' ? 'solid' : 'clear'}>
          <div className="in-button">
            <IonBadge>{results?.spots.total}</IonBadge>
            <BiTargetLock size={24} />
            <IonLabel className="tab-label">Spots</IonLabel>
          </div>
        </IonButton>
      </div>
      <div className="search-content">
        <SearchContent
          category={category}
          results={results}
          url={url}
          location={location}
        />
      </div>
    </div>
  )
}
