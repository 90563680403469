import React, { useEffect, useState } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { IonApp, IonRouterOutlet } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'

/* Theme variables */
import './theme/components.scss'
import './theme/variables.scss'
import InterventionPages from './pages/intervenant/router'
import AdminPages from './pages/admin/router'

import { useSelector } from 'react-redux'
import { RootState } from './stores/reducers'
import GuestPages from 'pages/guest/router'
import { CONTROLLER, getRole } from 'utils/const'
import ControlPages from 'pages/controller/router'
import ReportPages from 'pages/misc/report/router'
import AppUrlListener from './pages/AppUrlListener'
import { rehydrateStore } from 'api/helper'
import CalloutPages from 'pages/misc/callout/router'
import SpotPages from 'pages/misc/spot/router'
import DynamicRoleRouter from 'pages/misc/dynamicroleRouter'
import ToastWrapper from 'components/toast/toast'
import Map from 'components/map/Map'
import Menu from 'components/menu/Menu'
import Search from 'pages/misc/Search'
import SearchRouter from 'pages/misc/searchRouter'
import AccountRouter from 'pages/misc/accountRouter'

const App: React.FC = () => {
  const state = useSelector((state: RootState) => state)
  const [storeloaded, setstoreloaded] = useState(false)

  useEffect(() => {
    console.log('Redux store', state)
    if (!storeloaded) {
      setstoreloaded(true)
      rehydrateStore()
    }
  }, [state, storeloaded])

  useEffect(() => {
    if (state.theme.dark !== undefined) {
      updateThemeClass(state.theme.dark)
    } else {
      if (
        window.matchMedia &&
        window.matchMedia('(prefers-color-scheme: dark)').matches
      ) {
        updateThemeClass(true)
      }
      window
        .matchMedia('(prefers-color-scheme: dark)')
        .addEventListener('change', (e) => {
          updateThemeClass(e.matches)
        })
    }
  }, [state.theme.dark])

  const updateThemeClass = (dark: boolean) => {
    if (dark) {
      document.body.classList.add('dark')
      document.body.classList.remove('light')
    } else {
      document.body.classList.remove('dark')
      document.body.classList.add('light')
    }
  }

  const redirectHomepage = () => {
    const role = getRole(state.user.role)
    if (role === CONTROLLER) {
      return <Redirect to={`/${CONTROLLER}-role`} />
    }
    return <Redirect to={`/${role}`} />
  }

  return (
    <IonApp>
      <IonReactRouter>
        <AppUrlListener></AppUrlListener>
        {state.user.access_token && <Menu />}
        <IonRouterOutlet id="main">
          <Route path="/intervenant" component={InterventionPages} />
          <Route path="/controller" component={ControlPages} />
          <Route path="/admin-QR" component={AdminPages} />
          <Route path="/guest" component={GuestPages} />
          <Route path="/report" component={ReportPages} />
          <Route path="/callout" component={CalloutPages} />
          <Route path="/spot" component={SpotPages} />
          <Route path="/search" exact component={SearchRouter} />
          <Route path="/account" component={AccountRouter} />
          <Route path={`/${CONTROLLER}-role`} component={DynamicRoleRouter} />

          {/* <Route path="/map" component={Map} exact /> */}
          <Route path="/" render={redirectHomepage} exact />
        </IonRouterOutlet>
      </IonReactRouter>
      <ToastWrapper />
    </IonApp>
  )
}

export default App
