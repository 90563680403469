import { axiosApi } from './helper'

export function getGroups() {
  return axiosApi('groups', {}, 'get')
}

export function getGroup(id) {
  return axiosApi('groups/' + id, {}, 'get')
}

export function getGroupBySearch(search) {
  return axiosApi('groups/search', { value: search }, 'get')
}
