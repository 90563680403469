import { axiosApi } from './helper'

export function getEntranceByQrcode(qrcode: any) {
  return axiosApi('entranceByQrcode/', { qrcode: qrcode }, 'get')
}

export function getEntrances() {
  return axiosApi('entrances/', {}, 'get')
}

export function getEntranceBySpot(spotID: number) {
  return axiosApi(`entranceBySpot/${spotID}`, {}, 'get')
}

export function updateEntrance(entranceID: number, data: any) {
  return axiosApi(`update-entrance/${entranceID}`, data, 'put')
}

// nouvelle fonction get : entrée à proximité

export function getEntranceByPosition(userLatitude: number, userLongitude: number) {
  return axiosApi(`entrances/${userLatitude}/${userLongitude}`, {}, 'get')
}

// retourne les groupes, les bâtiments, les entrées et les spots corespondant à la recherche
// en attendant l'update du back, je renvoie une "fausse" réponse filtrée sur les entrées de Marseille

export function getDataBySearch(search: String) {
  return axiosApi(`search/${search}`, {}, 'get')
}

// retourne les informations d'une seule entrée avec ses spots associés

export function getSpotsByEntrance(entranceID: number) {
  return axiosApi(`spotsByEntrance/${entranceID}`)
}