import store from 'stores/store'
import { axiosApi } from './helper'
import { CONTROLLER, getRole } from 'utils/const'

export function scanQrcode(obj) {
  return axiosApi('qrcode', obj, 'post')
}

export function callout(spotID, jobID) {
  const obj = {
    spotID,
    jobID
  }

  const state = store.getState()
  const role = getRole(state.user.role)

  return role === CONTROLLER ? axiosApi('control', obj, 'post') : axiosApi('callOut', obj, 'post')
}

export function getCallout(id) {  
  const state = store.getState()
  const role = getRole(state.user.role)

  return role === CONTROLLER ? axiosApi(`control/${id}`, {}, 'get') : axiosApi(`callOut/${id}`, {}, 'get')
}

export function getCalloutScoring(id) {  
  const state = store.getState()
  const role = getRole(state.user.role)

  return role === CONTROLLER ? axiosApi(`control/${id}/scoring`, {}, 'get') : axiosApi(`callOut/${id}/scoring`, {}, 'get')
}
