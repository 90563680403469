import './Admin.scss'
import React, { useEffect, useState } from 'react'
import { IonButton, IonItem } from '@ionic/react'
import Container from 'layouts/containers/Container'
import { getGroup } from 'api/groupe'
import { Building } from 'interfaces/common'
import LayoutSearch from 'layouts/LayoutSearch'
import LayoutDefault from 'layouts/LayoutDefault'
import { useHistory } from 'react-router'
import BackButton from './backButton'

const AdminGroup: React.FC = (props: any) => {
  const [loading, setloading] = useState(true)
  const [error, seterror] = useState(false)
  const [errormsg, seterrormsg] = useState('')
  const [buildings, setbuildings] = useState<Array<Building>>([])
  const [groupName, setGroupName] = useState('')

  const { groupID } = props.match.params
  const startUrl = props.match.url.split('/')[1]

  const router = useHistory()

  useEffect(() => {
    getBuilding()
  }, [groupID])

  const getBuilding = () => {
    setloading(true)
    seterror(false)
    seterrormsg('')
    getGroup(groupID)
      .then((data: any) => {
        setGroupName(data.name)
        setbuildings(data.buildings)
      })
      .catch((error: any) => {
        if (error === 'errors.error_network') {
          seterrormsg(
            "Un problème est survenue, assurez vous d'être connecté à internet"
          )
        }
        seterror(true)
      })
      .finally(() => {
        setloading(false)
      })
  }

  const handleClick = (path: string) => {
    router.push(path, props.location.state)
  }

  if (error) {
    return (
      <LayoutDefault>
        <Container center>
          <p className="ion-text-center">
            {errormsg !== '' ? errormsg : 'Une erreur est survenue'}
          </p>
          <IonButton onClick={getBuilding}>Réessayer</IonButton>
        </Container>
      </LayoutDefault>
    )
  }

  return (
    <LayoutSearch>
      <Container paddingTop loading={loading}>
      <BackButton url="/" />
        <div className="admin_list">
          <div className="category-title">Groupe {groupName}</div>
          {buildings.map((building) => (
            <IonItem
              class="list-item"
              onClick={() =>
                handleClick(`/${startUrl}/buildings/${building.id}`)
              }
              key={building.id}
              lines="none"
              detail
              button>
              <div className="left_part">
                <div className="name">
                  {building.name} - {building.code}
                </div>
              </div>
            </IonItem>
          ))}
        </div>
      </Container>
    </LayoutSearch>
  )
}

export default AdminGroup
