import { Dialog } from '@capacitor/dialog'
import { IonButton } from '@ionic/react'
import React from 'react'

function DeleteForm({ onSubmit }: { onSubmit: () => void }) {
  const deleteAccount = async () => {
    const { value } = await Dialog.confirm({
      title: 'Confirmer la suppression',
      message: 'Êtes-vous sûr de vouloir supprimer votre compte ?'
    })

    if (!value) {
      return
    }

    onSubmit()
  }

  return (
    <div>
      <h1>Supprimer mon compte</h1>
      <p>
        Vous pouvez faire la demande pour supprimer votre compte en cliquant sur
        ce bouton
      </p>
      <IonButton color="danger" onClick={deleteAccount}>
        Supprimer mon compte
      </IonButton>
    </div>
  )
}

export default DeleteForm
