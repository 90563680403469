import './Admin.scss'
import React from 'react'
import {
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonRow,
  IonButton,
  IonIcon
} from '@ionic/react'
import { arrowBackCircle } from 'ionicons/icons'
import { useHistory } from 'react-router'

interface BackButtonParams {
  url?: string
}

const BackButton: React.FC<BackButtonParams> = ({ url = '/' }) => {
  const router = useHistory()

  const goBack = () => {
    router.goBack()
  }

  return (
    <IonRow className="ion-padding-bottom">
      {/* TODO FIX, programmatically use router.push with current location.state */}
      {/* <IonBackButton
        defaultHref={url}
        icon={arrowBackCircle}
        className="ionBackButton"
        text="Retour"
      /> */}
      <IonButton onClick={goBack} fill="clear" size="default" color="dark" className="back-button">
        <IonIcon size="large" slot="start" icon={arrowBackCircle} />
        Retour
      </IonButton>
    </IonRow>
  )
}

export default BackButton
