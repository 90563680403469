import React, { ReactNode } from 'react'
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonIcon,
  IonMenuButton,
  IonRippleEffect,
} from '@ionic/react'
import { menu } from 'ionicons/icons'
import Menu from 'components/menu/Menu'
import './LayoutDefault.scss'

interface LayoutProps {
  children: ReactNode
  title?: string
}

const LayoutDefault: React.FC<LayoutProps> = ({ children, title }) => {
  return (
    <IonPage>
      {/* <Menu /> */}
      <IonHeader mode="md" >
        <IonMenuButton autoHide={false} className="menu_button menu_button__text">
          <IonIcon icon={menu} color="dark"></IonIcon><span className="menu_button__label">MENU</span>
        </IonMenuButton>
        <IonRippleEffect type="unbounded"></IonRippleEffect>
      </IonHeader>
      <IonContent fullscreen id="content" className="content-page">
        {title && (
          <IonHeader collapse="condense">
            <IonToolbar>
              <IonTitle size="large">{title}</IonTitle>
            </IonToolbar>
          </IonHeader>
        )}
        <div>{children}</div>
      </IonContent>
    </IonPage>
  )
}

export default LayoutDefault
