import { askSuppression } from 'api/account'
import DeleteForm from 'components/account/DeleteForm'
import DeleteFormSubmitted from 'components/account/DeleteFormSubmitted'
import LayoutDefault from 'layouts/LayoutDefault'
import Container from 'layouts/containers/Container'
import React, { useState } from 'react'

function Delete() {
  const [step, setstep] = useState('form')

  const handleDelete = async () => {
    try {
      await askSuppression()
    } catch (e) {
      console.log(e)
      // We don't handle error for now, we just display the success message
    }
    setstep('success')
  }

  return (
    <LayoutDefault>
      <Container paddingTop paddingBottom>
        {step === 'form' && <DeleteForm onSubmit={handleDelete} />}
        {step === 'success' && <DeleteFormSubmitted />}
      </Container>
    </LayoutDefault>
  )
}

export default Delete
