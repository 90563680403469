import React, { useEffect, useState } from 'react'
import { IonButton, IonContent, IonPage } from '@ionic/react'
import './Login.scss'
import { loginsso } from '../../api/helper'
import Container from 'layouts/containers/Container'
import { RouteComponentProps } from 'react-router'

const SSO: React.FC<RouteComponentProps> = ({ location }) => {
  const [loading, setloading] = useState(false)
  const [error, seterror] = useState(false)
  const [errormsg, seterrormsg] = useState('')

  useEffect(() => {
    setloading(true)
    seterror(false)
    seterrormsg('')
    const query = new URLSearchParams(location.search)

    loginsso(query.get('code'))
      .catch((error: any) => {
        if (error === 'errors.error_network') {
          seterrormsg(
            "Un problème est survenue, assurez vous d'être connecté à internet"
          )
        }
        seterror(true)
      })
      .finally(() => setloading(false))
  }, [location])

  if (error) {
    return (
      <IonPage>
        <IonContent fullscreen>
          <Container center>
            <p className="ion-text-center">
              {errormsg !== ''
                ? errormsg
                : "Votre compte n'a pas accès à l'application ou n'a pas été trouvé."}
            </p>
            <IonButton href={`${process.env.REACT_APP_BACKEND}/loginapp`}>
              Réessayer
            </IonButton>
            <IonButton href={`/guest/login`} color="secondary">
              Revenir en arrière
            </IonButton>
          </Container>
        </IonContent>
      </IonPage>
    )
  }

  return (
    <IonPage>
      <IonContent fullscreen>
        <Container loading={loading} center>
          <p className="ion-text-center">
            Vous allez être redirigé vers l'application dans un instant
          </p>
        </Container>
      </IonContent>
    </IonPage>
  )
}

export default SSO
