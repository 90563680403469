import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonModal,
  IonText
} from '@ionic/react'
import { callout } from 'api/callout'
import { spotJobs } from 'api/spots'
import Container from 'layouts/containers/Container'
import React, { useCallback, useEffect, useState } from 'react'
import { RouteComponentProps, useHistory } from 'react-router'
import LayoutDefault from 'layouts/LayoutDefault'
import { Profession, Spot } from 'interfaces/common'
import store from 'stores/store'
import {
  delCurrentProfession,
  setCurrentProfession,
  setOnGoingScoring
} from 'stores/actions/user'
import { useSelector } from 'react-redux'
import { RootState } from 'stores/reducers'
import { getRole } from 'utils/const'
import animationData from '../../assets/animation/check.json'
import Lottie from 'react-lottie'

interface MatchParams {
  spotID: string
}

interface PreviousCallout {
  spot: Spot
  job: Profession
}

const lottieOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
}

const ProfessionSelection: React.FC<RouteComponentProps<MatchParams>> = ({
  match
}) => {
  const [error, seterror] = useState(true)
  const [errorMsg, setErrorMsg] = useState('')
  const [loading, setloading] = useState(false)
  const [simplesuccess, setsimplesuccess] = useState(false)
  const [previous, setprevious] = useState<PreviousCallout>()
  const [jobs, setjobs] = useState<Profession[]>([])
  const [selectedJob, setSelectedJob] = useState<Profession | null>(null)

  const router = useHistory()

  const state = useSelector((state: RootState) => state)
  const role = getRole(state.user.role)

  const toggleCallout = async (job: Profession | null) => {
    if (job === null) {
      return
    }

    setloading(true)
    seterror(false)
    setErrorMsg('')
    const jobID = job.id
    callout(match.params.spotID, jobID)
      .then((res: any) => {
        // En fonction de la réponse
        if (!res.endCallOut) {
          store.dispatch(setCurrentProfession(job))
          if (res.callout) {
            router.push(`/callout/inprogress/${res.callout}`)
          } else {
            router.push(`/callout/inprogress/${res.control}`)
          }
        } else {
          if (!res.form) {
            store.dispatch(delCurrentProfession())
            setsimplesuccess(true)
            setTimeout(() => {
              return router.push(`/${role}`)
            }, 2500)
          } else {
            const idScoring = res.callout || res.control
            store.dispatch(setOnGoingScoring(idScoring))
          }
        }
      })
      .catch((error: any) => {
        store.dispatch(delCurrentProfession())
        if (error?.data?.previous) {
          setprevious({ spot: error.data.spot, job: error.data.job })
        }

        let erreur =
          error.data && error.data.message && error.data.place
            ? `${error.data.message}<br />${error.data.place}`
            : null
        if (error === 'errors.error_network') {
          setErrorMsg(
            "Un problème est survenue, assurez vous d'être connecté à internet"
          )
        } else if (erreur) {
          setErrorMsg(erreur)
        }
        seterror(true)
      })
  }

  const fetchJobs = useCallback(() => {
    setloading(true)
    seterror(false)
    setErrorMsg('')

    spotJobs(match.params.spotID)
      .then((res: any) => {
        setjobs(res)
      })
      .catch((error: any) => {
        if (error === 'errors.error_network') {
          setErrorMsg(
            "Un problème est survenue, assurez vous d'être connecté à internet"
          )
        }
        seterror(true)
      })
      .finally(() => {
        setloading(false)
      })
  }, [match.params.spotID])

  const togglePreviousCallout = () => {
    callout(previous?.spot.id, previous?.job.id).then((res: any) => {
      // En fonction de la réponse
      if (!res.form) {
        store.dispatch(delCurrentProfession())
        setsimplesuccess(true)
        setTimeout(() => {
          return router.push(`/${role}`)
        }, 2500)
      } else {
        store.dispatch(setOnGoingScoring(res.callout))
      }
    })
  }

  useEffect(() => {
    fetchJobs()
  }, [fetchJobs])

  const user = useSelector((state: RootState) => state.user)
  const currentJob =
    user.currentProfession && jobs.length
      ? jobs.find((j) => j.id === user.currentProfession!.id)
      : null

  const createMarkup = (msg: string): { __html: string } => {
    return { __html: msg !== '' ? msg : 'Une erreur a eu lieu' }
  }

  const cancelPrevious = () => {
    return router.push(`/${role}`)
  }

  if (previous) {
    return (
      <LayoutDefault>
        <Container center>
          <IonText>
            <p className="ion-text-center">
              Vous avez déjà une intervention en cours
            </p>
            <h3>{previous.spot.name}</h3>
            <p>{previous.spot.description}</p>
            <p>Métier : {previous.job.name}</p>
          </IonText>
          <IonButton onClick={togglePreviousCallout}>Terminer</IonButton>
          <IonButton onClick={cancelPrevious} fill="outline" color="dark">
            Annuler
          </IonButton>
        </Container>
      </LayoutDefault>
    )
  }

  if (simplesuccess) {
    return (
      <LayoutDefault>
        <Container center>
          <Lottie options={lottieOptions} width={200} />
          <div className="ion-text-center">
            <IonText color="success">
              Intervention complétée avec succès
            </IonText>
          </div>
          <div className="ion-text-center">
            <IonText>Vous allez être redirigé</IonText>
          </div>
        </Container>
      </LayoutDefault>
    )
  }

  if (error) {
    return (
      <LayoutDefault>
        <Container center>
          <p
            className="ion-text-center"
            dangerouslySetInnerHTML={createMarkup(errorMsg)}></p>
          <IonButton onClick={fetchJobs}>Réessayer</IonButton>
        </Container>
      </LayoutDefault>
    )
  }

  if (jobs.length === 0 && !loading) {
    return (
      <LayoutDefault>
        <Container center>
          <IonCard>
            <IonCardContent>
              <p>Aucun de vos métiers n'est affilié à ce spot</p>
              <IonButton onClick={fetchJobs}>Réessayer</IonButton>
              <IonButton onClick={() => router.push(`/${role}`)}>Annuler</IonButton>
            </IonCardContent>
          </IonCard>
        </Container>
      </LayoutDefault>
    )
  }

  if (jobs.length === 1 && !loading) {
    toggleCallout(jobs[0])
    return null
  }

  // Si on a un currentjob ça veut dire que l'on a une activité en cours et qu'on veut la terminer
  if (currentJob && !loading) {
    toggleCallout(currentJob)
    return null
  }

  return (
    <LayoutDefault>
      <Container paddingTop loading={loading}>
        <IonList lines="full">
          <IonListHeader mode="ios">
            <IonLabel>Sélectionnez un métier parmis ceux proposés</IonLabel>
          </IonListHeader>

          {jobs.map((job: any, index: number) => (
            <IonItem
              button
              key={job.id}
              lines={index >= jobs.length - 1 ? 'none' : undefined}
              onClick={() => setSelectedJob(job)}>
              <IonLabel>{job.name}</IonLabel>
            </IonItem>
          ))}
        </IonList>

        <IonModal
          isOpen={!!selectedJob}
          animated={true}
          onDidDismiss={() => setSelectedJob(null)}
          swipeToClose={true}
          showBackdrop={true}>
          <div className="job-select__modal--wrapper">
            <IonText className="ion-text-center ion-padding job-select__modal--text" color="dark">
              Validez vous le métier sélectionné ? <br />
              <b>{selectedJob?.name}</b>
            </IonText>
            <IonButtons className="ion-padding">
              <IonButton
                color="primary"
                fill="solid"
                className="job-select__modal--button"
                onClick={() => toggleCallout(selectedJob)}>
                <b>OUI</b>
              </IonButton>
              <IonButton
                color="dark"
                fill="outline"
                className="job-select__modal--button"
                onClick={() => setSelectedJob(null)}>
                <b>NON</b>
              </IonButton>
            </IonButtons>
          </div>
        </IonModal>
      </Container>
    </LayoutDefault>
  )
}

export default ProfessionSelection
