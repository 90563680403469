import { Profession } from "interfaces/common"

export function setUserInformations(data: any) {
  return {
    type: "ACCOUNT_SET_DATAS",
    data: data,
  }
}
export function delUserInformations() {
  return {
    type: "ACCOUNT_DEL_DATAS",
  }
}

export function setTokens(token: any) {
  return {
    type: "AUTH_SET_TOKEN",
    data: token,
  }
}

export function setRememberMe(boolean: boolean) {
  return {
    type: "AUTH_SET_REMEMBER_ME",
    data: boolean,
  }
}

export function delRememberMe() {
  return {
    type: "AUTH_DEL_REMEMBER_ME",
  }
}

export function delTokens() {
  return {
    type: "AUTH_DEL_TOKEN",
  }
}

export function setCurrentProfession(data: Profession) {
  return {
    type: "ACCOUNT_SET_CURRENT_PROFESSION",
    data: data,
  }
}

export function delCurrentProfession() {
  return {
    type: "ACCOUNT_DEL_CURRENT_PROFESSION",
  }
}

export function setOnGoingScoring(data: number) {
  return {
    type: "ACCOUNT_SET_ON_GOING_SCORING",
    data: data,
  }
}

export function delOnGoingScoring() {
  return {
    type: "ACCOUNT_DEL_ON_GOING_SCORING",
  }
}

export function setControllerRole(data: string) {
  return {
    type: "USER_SET_CONTROLLER_ROLE",
    data,
  }
}

// @TODO Delete user info, and token when the user locked his phone more than 15 mn and closes window on cumptuer ??

// export function userInfoDel() {
//   return {
//     type: "USER_INFO_DEL",
//   }
// }