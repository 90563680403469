import React from 'react'

import animationData from '../../assets/animation/check.json'
import Lottie from 'react-lottie'

const lottieOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
}

function DeleteFormSubmitted() {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column'
      }}>
      <h1>Votre demande a été prise en compte</h1>
      <p>Votre demande de suppression de compte a bien été prise en compte</p>

      <Lottie options={lottieOptions} width={200} />
    </div>
  )
}

export default DeleteFormSubmitted
