import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { RootState } from "../stores/reducers";
import { useSelector } from "react-redux";

/**
 * This middleware will redirect to the role home page anyone who is already logged in
 */
const GuestMiddleware: React.FC = ({ children }) => {
  const [redirect, setRedirect] = useState(false);

  const user = useSelector((state: RootState) => state.user);

  useEffect(() => {
    if (user.access_token) {
      setRedirect(true);
    }
  }, [user]);

  if (redirect) {
    return <Redirect to="/" />;
  }

  return <>{children}</>;
};

export default GuestMiddleware;
