type LatLng = {
  0: number
  1: number
}

export function getDistance(origin: LatLng, destination: LatLng) {
  // return distance in meters
  const lng1 = toRadian(origin[1]),
    lat1 = toRadian(origin[0]),
    lng2 = toRadian(destination[1]),
    lat2 = toRadian(destination[0])

  const deltaLat = lat2 - lat1
  const deltaLng = lng2 - lng1

  const a =
    Math.pow(Math.sin(deltaLat / 2), 2) +
    Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(deltaLng / 2), 2)
  const c = 2 * Math.asin(Math.sqrt(a))
  const EARTH_RADIUS = 6371
  return c * EARTH_RADIUS * 1000
}

function toRadian(degree: number) {
  return (degree * Math.PI) / 180
}

// var distance = getDistance([lat1, lng1], [lat2, lng2])
