import React, { useState } from 'react'
import {
  IonButton,
  IonCard,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonSpinner,
  IonText
} from '@ionic/react'
import { closeCircleOutline } from 'ionicons/icons'
import './Login.scss'
import {
  forgotPassword,
  confirmCode,
  resetNewPassword
} from '../../api/account'

import ValidatePassword from '../../components/ValidatePassword/ValidatePassword'
import Validate from 'assets/validation'

const ForgotPassword: React.FC = () => {
  const [showModal, setShowModal] = useState(false)
  const [loading, setloading] = useState(false)
  const [email, setemail] = useState('')
  const [code, setcode] = useState('')
  const [step, setstep] = useState(0)
  const [error, seterror] = useState('')
  const [password, setpassword] = useState('')
  const [passwordConfirm, setpasswordConfirm] = useState('')

  // Passe à l'etape suivante
  const nextStep = () => {
    setstep(step + 1)
  }

  // Vérifie et envoie le code de reinitialisation
  const sendMail = (e: any) => {
    // Vérifie si l'email est renseigné et valide
    if (email !== '' && !Validate('email', email)) {
      setloading(true)
      forgotPassword(email)
        .then(
          () => {
            // Efface l'erreur et passe à l'étape suivante
            seterror('')
            nextStep()
          },
          (error: any) => {
            seterror(error)
          }
        )
        .finally(() => setloading(false))
    } else {
      // Si l'email n'est pas valide affiche l'erreur
      e.preventDefault()
      seterror(Validate('email', email))
    }
  }

  // Vérifie que le code est valide et autorise le changement de mot de passe
  const getConfirmation = (e: any) => {
    if (code !== '') {
      setloading(true)
      confirmCode(email, code)
        .then(
          () => {
            // Efface l'erreur et passe à l'étape suivante
            seterror('')
            setstep(2)
          },
          (error: any) => {
            // Si le code n'est pas valide affiche l'erreur
            seterror(error)
          }
        )
        .finally(() => setloading(false))
    } else {
      // Si aucun code n'est entré affiche l'erreur et ne fait rien
      e.preventDefault()
      seterror('Veuillez renseigner le code reçu par mail')
    }
  }

  // Effectue le changement de mot de passe si ils sont identiques
  const changePassword = (e: any) => {
    // Vérifie que les deux mots de passe sont identique
    if (
      password === passwordConfirm &&
      password !== '' &&
      passwordConfirm !== ''
    ) {
      // Effectue le changement de mot de passe
      setloading(true)
      resetNewPassword(email, password, code)
        .then(
          () => {
            seterror('')
            setShowModal(false)
            setstep(0)
          },
          (error: any) => {
            // Affiche l'erreur
            seterror(error)
          }
        )
        .finally(() => setloading(false))
    } else {
      // Si les mots de passe sont différent affiche l'erreur et ne fait rien
      e.preventDefault()
      seterror('Les mots de passe de sont pas identiques')
    }
  }

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <>
            <div>
              <IonText>
                Vous devriez avoir reçu un mail qui contient un code vous
                permettant de réinitialiser votre mot de passe. Veuillez saisir
                ce code.
              </IonText>
              <IonItem>
                <IonInput
                  value={code}
                  placeholder="123456"
                  onIonChange={(e) => setcode(e.detail.value!)}
                  type="number"
                />
              </IonItem>
            </div>
            <div className="btn_wrapper">
              {error && (
                <IonText class="error" color="danger">
                  {error}
                </IonText>
              )}
              <IonButton
                size="default"
                onClick={(e) => getConfirmation(e)}
                class="btn_rounded">
                Valider
              </IonButton>
            </div>
          </>
        )

      case 2:
        return (
          <>
            <div>
              <ValidatePassword value={password}>
                <IonText>
                  Votre nouveau mot de passe doit se composer de :
                </IonText>
              </ValidatePassword>
              <IonCard>
                <IonList>
                  <IonItem>
                    <IonInput
                      value={password}
                      placeholder="Nouveau mot de passe"
                      onIonChange={(e) => setpassword(e.detail.value!)}
                      type="password"
                    />
                  </IonItem>
                  <IonItem>
                    <IonInput
                      value={passwordConfirm}
                      placeholder="Confirmer le mot de passe"
                      onIonChange={(e) => setpasswordConfirm(e.detail.value!)}
                      type="password"
                    />
                  </IonItem>
                </IonList>
              </IonCard>
            </div>
            <div className="btn_wrapper">
              {error && (
                <IonText class="error" color="danger">
                  {error}
                </IonText>
              )}
              <IonButton
                size="default"
                onClick={(e) => changePassword(e)}
                class="btn_rounded">
                Valider
              </IonButton>
            </div>
          </>
        )

      case 0:
      default:
        return (
          <>
            <div>
              <IonText>
                Merci de saisir votre e-mail pour recevoir le code de
                réinitialisation de votre mot de passe.
              </IonText>
              <IonItem>
                <IonInput
                  value={email}
                  placeholder="Email"
                  onIonChange={(e) => setemail(e.detail.value!)}
                  type="email"
                />
              </IonItem>
            </div>
            <div className="btn_wrapper">
              {error && (
                <IonText class="error" color="danger">
                  {error}
                </IonText>
              )}
              <IonButton
                size="default"
                onClick={(e) => sendMail(e)}
                class="btn_rounded">
                Valider
              </IonButton>
            </div>
          </>
        )
    }
  }

  const closeModal = () => {
    setShowModal(false)
    setstep(0)
  }

  return (
    <>
      <IonModal
        isOpen={showModal}
        cssClass="forgot-password-modal"
        animated={true}
        onDidDismiss={() => closeModal()}
        swipeToClose={true}
        showBackdrop={true}>
        <IonIcon
          icon={closeCircleOutline}
          color="white"
          onClick={() => closeModal()}
          class="close-modal"></IonIcon>

        {loading ? (
          <div className="container-default container-default--center">
            <IonSpinner />
          </div>
        ) : (
          renderStep()
        )}
      </IonModal>

      <IonItem onClick={() => setShowModal(true)} lines="none" detail={false}>
        <IonLabel color="medium" class="forgot-password">
          Mot de passe oublié ?
        </IonLabel>
      </IonItem>
    </>
  )
}

export default ForgotPassword
