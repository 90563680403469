import React, { ReactNode, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getRole } from '../utils/const'

interface RoleMiddlewareProps {
  children: ReactNode
  roles: string[]
}

/**
 * This middleware will redirect anyone who tries to access routes inside without the any of the role specified
 */
const RoleMiddleware: React.FC<RoleMiddlewareProps> = ({ children, roles }) => {
  const user = useSelector((state: any) => state.user)
  const router = useHistory()

  useEffect(() => {
    const role = getRole(user.role)
    if (!roles.includes(role)) {
      router.replace(`/${role}`)
    }
  }, [roles, router, user.role])

  return <>{children}</>
}

export default RoleMiddleware
