import React from 'react'
import { Route, RouteComponentProps } from 'react-router-dom'
import { IonRouterOutlet } from '@ionic/react'
import AuthMiddleware from 'middlewares/AuthMiddleware'
import QrScanner from '../QrScanner'
import RoleMiddleware from 'middlewares/RoleMiddleware'
import { CONTROLLER, INTERVENANT } from 'utils/const'
import ScoringSubmission from '../ScoringSubmission'
import CalloutInProgress from '../CalloutInProgress'

const CalloutPages: React.FC<RouteComponentProps> = ({ match }) => {
  return (
    <IonRouterOutlet>
      <AuthMiddleware>
        <RoleMiddleware roles={[INTERVENANT, CONTROLLER]}>
          <Route path={`${match.url}/scan`} component={QrScanner} exact />
          <Route
            path={`${match.url}/inprogress/:calloutID`}
            component={CalloutInProgress}
          />
          <Route
            path={`${match.url}/:calloutID/scoring`}
            component={ScoringSubmission}
            exact
          />
        </RoleMiddleware>
      </AuthMiddleware>
    </IonRouterOutlet>
  )
}

export default CalloutPages
