import './Admin.scss'
import React, { useCallback, useEffect, useState } from 'react'
import LayoutDefault from '../../layouts/LayoutDefault'
import { IonButton, IonItem, IonText, IonToolbar, IonButtons, IonBackButton } from '@ionic/react'
import Container from '../../layouts/containers/Container'
import { getSpotsByEntrance } from '../../api/entrance'
import { Entrance } from '../../interfaces/common'
import { useHistory } from 'react-router'
import BackButton from './backButton'

const AdminEntrance: React.FC = (props: any) => {
  const [loading, setloading] = useState(true)
  const [error, seterror] = useState(false)
  const [errormsg, seterrormsg] = useState('')
  const [entrance, setEntrance] = useState<Entrance>({
    id: 0,
    name: '',
    code: '',
    commonID: '',
    zipcode: '',
    commune: '',
    address: '',
    latitude: 0,
    longitude: 0,
    uuid: '',
    spots: []
  })

  const entranceId = parseInt(props.match.params.entranceID)
  const startUrl = props.match.url.split('/')[1]
  const router = useHistory()

  const getEntrance = useCallback(() => {
    setloading(true)
    seterror(false)
    seterrormsg('')
    getSpotsByEntrance(entranceId)
      .then((result: any) => {
        setEntrance(result)
      })
      .catch((error: any) => {
        console.log(error)
        if (error === 'errors.error_network') {
          seterrormsg(
            "Un problème est survenue, assurez vous d'être connecté à internet"
          )
        }
        seterror(true)
      })
      .finally(() => {
        setloading(false)
      })
  }, [entranceId])

  useEffect(() => {
    getEntrance()
  }, [getEntrance])

  const selectSpot = (spot: any) => {
    console.log(entrance)
    console.log(props.location.state)
    router.push('/report/form', { ...props.location.state, spot })
  }
  const handleClick = (spot: any, path: any) => {
    console.log(startUrl)
    if (startUrl === 'report') {
      selectSpot(spot)
    }
    else {
      router.push(path, props.location.state)
    }
  }

  if (error) {
    return (
      <LayoutDefault>
        <Container center>
          <p className="ion-text-center">
            {errormsg !== '' ? errormsg : 'Une erreur est survenue'}
          </p>
          <IonButton onClick={getEntrance}>Réessayer</IonButton>
        </Container>
      </LayoutDefault>
    )
  }

  return (
    <LayoutDefault>
      <Container paddingTop loading={loading}>
        <BackButton url="/" />
  
        <div className="category-title">
          Entrées <br /> {entrance.name} {entrance.zipcode} {entrance.commune}
        </div>

        <IonItem class="item-address thumbnail" lines="none" detailIcon="none">
          <div className="left_part">
            <div className="address-label">Code : {entrance.code}</div>
          </div>
        </IonItem>

        <IonText color="dark">
          <div className="spot-list">Liste des spots</div>
        </IonText>

        {entrance.spots?.map((spot) => (
          <IonItem
            key={spot.id}
            class="item-address thumbnail"
            lines="none"
            detail
            button
            onClick={() => handleClick(spot, `/${startUrl}/spots/${spot.id}`)}>
            <div className="spot_part">
              <div className="address-label">{spot.name}</div>
            </div>
          </IonItem>
        ))}
      </Container>
    </LayoutDefault>
  )
}

export default AdminEntrance
