import React from 'react'
import { IonIcon } from '@ionic/react'
import { close } from 'ionicons/icons'
import './closeReport.scss'
import { useHistory } from 'react-router'
import { CONTROLLER, getRole } from 'utils/const'
import { useSelector } from 'react-redux'
import { RootState } from 'stores/reducers'
import { userState } from 'interfaces/common'

interface ReportCloseProps {
  fromPath?: string
}

export const getQuitReportUrl = (user: userState, fromPath?: string):string => {
    let url:string = "/";
    let role:string = getRole(user.role)

    if(fromPath && !fromPath.startsWith('/report')) {
      url = fromPath
    } else {
      url += (role === CONTROLLER) 
        ? (user.controllerRole !== '' )
          ? user.controllerRole
          : `${CONTROLLER}-role`
        : role
    }
    return url
}

const ReportClose: React.FC<ReportCloseProps> = ({fromPath}) => {
  const router = useHistory()
  const user = useSelector((state: RootState) => state.user)

  const quitReport = () => {
    router.push(getQuitReportUrl(user, fromPath))
  }
  return (
    <div className="close-report" onClick={quitReport}>
      <IonIcon icon={close} />
    </div>
  )
}
export default ReportClose