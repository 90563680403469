import React from 'react'
import { Route, RouteComponentProps } from 'react-router-dom'
import { IonRouterOutlet } from '@ionic/react'
import AuthMiddleware from 'middlewares/AuthMiddleware'
import Search from './Search'
import DeleteForm from 'components/account/DeleteForm'
import Delete from 'pages/account/delete'

const AccountRouter: React.FC<RouteComponentProps> = ({ match }) => {
  return (
    <IonRouterOutlet>
      <AuthMiddleware>
        <Route exact path={`${match.url}/delete`} component={Delete} />
      </AuthMiddleware>
    </IonRouterOutlet>
  )
}

export default AccountRouter
