import React, { useEffect, useState } from 'react';
import { IonToast } from '@ionic/react';
import { useSelector } from 'react-redux';
import { RootState } from 'stores/reducers';
import { toast, toastState } from 'interfaces/common';
import store from 'stores/store';
import { delCurrentMessage } from 'stores/actions/toast';

const ToastWrapper: React.FC = () => {
  const state: toastState = useSelector((state: RootState) => state.toast)
  
  const [currentToast, setCurrentToast] = useState<toast>();

  useEffect(()=>{
    setCurrentToast(state.notifs[0])
  }, [state.notifs])

  const onDidDismiss = () => {
    //on remet le message à courant à undefined
    setCurrentToast(undefined);
    // on enlève la notif dans le store
    store.dispatch(delCurrentMessage())
  }

  const show = !!(currentToast)
  // @ts-ignore
  const message = currentToast ? currentToast.message : '';
  // @ts-ignore
  const duration = currentToast ? currentToast.duration : 0;
  return ( 
    <>
      <IonToast
        isOpen={show}
        onDidDismiss={onDidDismiss}
        message={message}
        duration={duration}
      />
    </>
  );
}
export default ToastWrapper