export const INTERVENANT = 'intervenant'
export const CONTROLLER = 'controller'
export const ADMIN_QR = 'admin-QR'

export const getRole = (role: any) => {
  switch (role) {
    case 'ROLE_INTERVENANT':
      return INTERVENANT

    case 'ROLE_CONTROLLER':
      return CONTROLLER

    case 'ROLE_ADMIN_QR':
      return ADMIN_QR

    default:
      return INTERVENANT
  }
}
