import React from 'react'
import { Route, RouteComponentProps } from 'react-router-dom'
import { IonRouterOutlet } from '@ionic/react'
import InterventionIndex from '.'
import AuthMiddleware from 'middlewares/AuthMiddleware'
import RoleMiddleware from 'middlewares/RoleMiddleware'
import { INTERVENANT } from 'utils/const'

const InterventionPages: React.FC<RouteComponentProps> = ({ match }) => {
  return (
    <IonRouterOutlet>
      <AuthMiddleware>
        <RoleMiddleware roles={[INTERVENANT]}>
          <Route exact path={match.url} component={InterventionIndex} />
        </RoleMiddleware>
      </AuthMiddleware>
    </IonRouterOutlet>
  )
}

export default InterventionPages
