import { IonButton, IonIcon } from '@ionic/react'
import React from 'react'
import { useHistory } from 'react-router'
import './style.scss'

const ScanButton: React.FC = () => {
  const router = useHistory()

  const openScanner = async () => {
    router.push('/callout/scan')
    // if (!isPlatform('cordova')) {
    //   router.push('/callout/scan')
    // } else {
    //   const data = await BarcodeScanner.scan({
    //     prompt: 'Veuillez scanner votre QR code'
    //   })
    //   if (data && !loading) {
    //     setloading(true)
    //     scanHandler(data.text)
    //       .then((callout) => redirectionHandler(router, callout))
    //       .catch((err) => {
    //         // Afficher l'erreur
    //       })
    //       .finally(() => setloading(false))
    //   }
    // }
  }

  // important de chosir une couleur pour le bouton, car par defaut le dark mode modifie le bouton du vert au bleu

  return (
    <IonButton onClick={openScanner} className="full" strong>
      <IonIcon slot="start" icon="assets/images/qr-code.svg"></IonIcon>Scan QR Code
    </IonButton>
  )
}

export default ScanButton
