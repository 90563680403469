import {
  IonItem,
  IonLabel,
  IonList,
  IonListHeader
} from '@ionic/react'
import Container from 'layouts/containers/Container'
import React, { useState } from 'react'
import { Redirect, RouteComponentProps } from 'react-router'
import LayoutDefault from 'layouts/LayoutDefault'
import { ADMIN_QR, CONTROLLER,  } from 'utils/const'
import store from 'stores/store'
import { setControllerRole } from 'stores/actions/user'

interface MatchParams {}

interface role {url: string, label: string, id: string}
const ROLES_DATA: role[] = [
  {url: `/${CONTROLLER}`, label: "Contrôleur", id: CONTROLLER},
  {url: `/${ADMIN_QR}`, label: "Administrateur", id: ADMIN_QR}
]

const ControlleurSelection: React.FC<RouteComponentProps<MatchParams>> = () => {

  const [url, setUrl] = useState('')

  const redirectRole = (role: role) => {
    store.dispatch(setControllerRole(role.id))
    setUrl(role.url)
  }
  
  if(url.length) {
    return <Redirect to={url} />
  }

  return (
    <LayoutDefault>
      <Container paddingTop >
        <IonList lines="full">
          <IonListHeader mode="ios">
            <IonLabel>Sélectionnez un rôle parmis ceux proposés</IonLabel>
          </IonListHeader>

          {ROLES_DATA.map((role, index: number) => (
            <IonItem
              button
              key={role.label}
              lines={index >= ROLES_DATA.length - 1 ? 'none' : undefined}
              onClick={() => redirectRole(role)}>
              <IonLabel>{role.label}</IonLabel>
            </IonItem>
          ))}
        </IonList>
      </Container>
    </LayoutDefault>
  )
}

export default ControlleurSelection
