import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { RootState } from "../stores/reducers";
import { useSelector } from "react-redux";

/**
 * This middleware will redirect to login page anyone who is not logged in
 */
const AuthMiddleware: React.FC<any> = ({children, computedMatch}) => {
  const [redirect, setRedirect] = useState(false);

  const user = useSelector((state: RootState) => state.user);

  useEffect(() => {
    if (!user.access_token) {
      setRedirect(true);
    }
  }, [user]);

  if (redirect) {
    return <Redirect to={`/guest/login`} />;
  }

  // Si on a un formulaire à valider et que l'on est pas déjà sur la page
  const scoringRegex= RegExp(/\/callout\/\d+\/scoring/)
  if(user.onGoingScoring && computedMatch && !scoringRegex.test(computedMatch.path)) {
    return <Redirect to={`/callout/${user.onGoingScoring}/scoring`} />;
  }

  return <>{children}</>;
};

export default AuthMiddleware;
